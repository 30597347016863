.wrapper-assignpage {
  .wrapper-collgedetail-form {
    .overview_studentdetails {
      .overview_studentdetails_bx {
        .student-textarea {
          padding: 21px 20px 35px 20px;
        }
        input[type="date"] {
          position: relative;
          border: 0;
          &::-webkit-calendar-picker-indicator {
            left: 0 !important;
            position: relative !important;
            filter: invert(1);
          }
        }
      }
    }
    .select-btn-area {
      padding: 0px 25px 25px 25px;
      border-bottom: 1px dashed #fff;
      margin-bottom: 20px;

      button {
        margin-right: 20px;
        border: 0;
        border-radius: 20px;
        padding: 2px 15px;

        &.active {
          background: #1e2742;
          color: #fff;
        }
      }
    }
    .subheader-right-btn2 {
      max-width: 925px;
    }
  }
  .addoverview-data2 {
    label {
      color: #e19d28e0;
      margin: 10px 0px;
    }
  }
  .subheader-right-btn3 {
    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #fff;
      background-color: #f5444e;
    }
  }
}

a {
  text-decoration: none;
}
