@font-face {
    font-family: 'Al Valenciaga PersonalUseOnly';
    src: url('../../../public/assets/fonts/al_valenciaga_PersonalUseOnly.woff2') format('woff2'),
        url('../../../public/assets/fonts/al_valenciaga_PersonalUseOnly.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-Medium.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../public/assets/fonts/Poppins-Black.woff2') format('woff2'),
        url('../../../public/assets/fonts/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia';
    src: url('../../../public/assets/fonts/Sofia-Regular.woff2') format('woff2'),
        url('../../../public/assets/fonts/Sofia-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
