.subadmin-card {
  -webkit-box-shadow: 0px 0px 12px 0px rgba(128, 123, 128, 1);
  -moz-box-shadow: 0px 0px 12px 0px rgba(128, 123, 128, 1);
  box-shadow: 0px 0px 12px 0px rgb(248, 153, 248);
  padding: 1rem;
  word-break: break-all;
  border-radius: 12px;
  span {
    color: #000 !important;
    font-size: 12px;
    svg {
      color: rgba(0, 0, 0, 0.925) !important;
      font-size: 10px;
    }
  }
}
.wrapper-assignpage {
  .add-facility.row {
    background: #ffffff;
  }
  .css-t3ipsp-control {
    background-color: #fafbfd !important;
    border: none !important;
    box-shadow: none !important;
  }
  .react-confirm-alert-body {
    background-color: #000000 !important;
  }
  .tabbing-button.active button {
    padding-bottom: 6px;
  }
  .modal-lg {
    --bs-modal-width: 1000px !important;
  }

  .check-heading {
    font-size: 13px !important;
    word-break: break-word;
  }
  .add-btn {
    border-radius: 50px !important;
    font-size: 11px !important;
    padding: 5px 8px !important;
  }
  .application {
    background: #8ed3db00;
    border: 1px solid #e3dcdc;
    padding: 1.5rem;
    border-radius: 12px;
  }
  .assigned-college {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .play-icon {
    color: #000000 !important;
    margin-right: 6px;
    font-size: 10px;
  }
  .select__control.css-13cymwt-control {
    color: black !important;
  }
  li {
    color: black;
  }
  .select__single-value.css-1dimb5e-singleValue {
    color: rgb(116, 115, 115) !important;
    font-size: 12px;
  }
  .pagination .active a {
    color: #050505 !important;
    padding: 10px 10px;
    background-color: #bebbbb;
    border-radius: 10px;
    margin: 0px 10px;
  }
  .pagination a {
    color: white !important;
    text-decoration: none;
    background-color: #1a1a1a;
    padding: 10px;
    font-size: 13px;
    border-radius: 12px;
    margin-right: 10px;
  }
  .pagination .previous a,
  .pagination .next a {
    color: white !important;
    text-decoration: none;
    background-color: #000000;
    padding: 12px 6px;
    font-size: 13px;
    border-radius: 10px;
  }
  .wrapper-collgedetail-form {
    margin-bottom: 1rem;
  }
  .add-date {
    background: #8ed3db00;
    border: 1px solid #e3dcdc;
  }
  .admis_procedure {
    background: #8ed3db00;
    border: 1px solid #e3dcdc;
  }
  .about-img {
    background-color: #32dec854;
  }

  .col-md-2.image-plus.subheader-right-btm {
    background: #8ed3db00;
    border: 1px solid #e3dcdc;
    button {
      color: #128d77;
    }
  }

  .medical.facility-check {
    background: #8ed3db00;
    border: 1px solid #e3dcdc;
    border-radius: 6px;
    h2 {
      color: #000 !important;
    }
  }
  .subadmin-testimonial-card {
    background: #d4eeff !important;
    .MuiRating-iconFilled {
      color: #d8e70e;
      font-size: 18px;
    }
    .chip {
      background: #db604aa8;
    }
  }
  .addcollegeplacement-addcompany {
    background: #ffffff !important;
    border-radius: 12px !important;
    border: 1px solid lightgrey;
  }
  .student-textarea {
    background: #ffffff !important;
    border-radius: 12px !important;
    border: 1px solid lightgrey;
    padding: 1rem;
  }
  .form-control {
    background-color: transparent;
    border: 1px solid lightgrey;
    color: rgb(2, 2, 2);
    font-weight: 400;
    font-size: 11px;
  }
  p,
  span {
    color: #3c3434 !important;
  }
  label,
  td,
  th {
    font-size: 16px !important;
    margin: 0;
    color: #3c3434 !important;
    @media (max-width: 1640px) {
      font-size: 14px !important;
    }
    @media (max-width: 1440px) {
      font-size: 13px !important;
    }
  }
  label {
    display: block;
  }
  h1:not(.html_string h1),
  h2:not(.html_string h2),
  h3:not(.html_string h3),
  h4:not(.html_string h4),
  h5:not(.html_string h5),
  h6:not(.html_string h6) {
    color: #000000 !important;
    // font-size: 20px;

    @media (max-width: 1640px) {
      // font-size: 18px;
    }
    @media (max-width: 1440px) {
      // font-size: 13px;
    }
  }

  .heading-36 {
    font-weight: bold;
    font-size: 22px !important;
    &::after {
      content: "";
      display: block;
      border-top: solid 2px #080808;
      width: 50%;
      height: 1px;
      position: absolute;
      top: 110%;
      z-index: 1;
    }
  }
  .subheader {
    .subheader-title {
      .userprofiledata {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
        .userprofile-datatext {
          .btn-tl-id {
            display: block;
            background: #1e2742;
            border: 0;
            padding: 5px 15px;
            color: #fff;
            margin-bottom: 10px;
          }
          h6:not(.html_string h6) {
            display: inline-block;
            margin-right: 45px;
          }
          p {
            display: inline-block;
          }
          .btn-change {
            background: #00be8b8c;
            padding: 5px 15px;
            color: #fff;
            border: 0;
            border-radius: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .css-13cymwt-control {
    border: 1px solid lightgrey !important;
    background-color: transparent !important;
  }

  .select__input {
    color: black !important;
    font-size: 12px !important;
  }
  .select__control--is-focused {
    background-color: transparent !important;
    border: 1px solid lightgrey !important;
  }
  .lightheading {
    margin-bottom: 20px;
    span {
      color: #a2a2a2;
    }
    .fa-angle-right {
      margin: 0 10px;
    }
  }

  .assignrepport-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .selected-data {
      display: flex;
      justify-content: space-between;
      .select-content {
        display: flex;
        margin-right: 30px;

        label {
          margin-left: 5px;
          color: #95a1bb;
        }
        input:checked ~ label {
          color: #fff;
        }
      }
    }
    .my-5 {
      margin-bottom: 100px;
    }
    .assigntime-bx {
      display: flex;

      input[type="date"] {
        position: relative;
        padding: 0 10px;
        background: transparent;
        color: #fff;
        border: 0;
        &::-webkit-calendar-picker-indicator {
          position: absolute;
          left: 10px;
          filter: invert(1);
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .select-day {
        background: #403f52;
        color: #fff;
        border: 0;
        padding: 2px 6px;
      }
      .assigntime {
        // background: #1e2742;
        border-radius: 8px;
        padding: 5px 10px;

        button {
          background: #8d77774d;
          color: #fff;
          border: 0;
          border-radius: 8px;
          padding: 5px 15px;
          margin: 0 5px;
        }
      }
    }
  }
  .add-course .multiple-select-2 {
    background: transparent !important;
    border-radius: 0.375rem;
    border: 1px solid lightgrey;
  }
  .add-course .course-sp {
    background: #d4eeff;
    border-radius: 6px;
  }
  .subheader-search input:focus {
    color: rgb(7, 7, 7);
  }
  .third_bottom {
    background: #d4eeff;
  }
  .css-1q9fcw6-control {
    outline: lightgrey;

    background-color: transparent;
    border-color: lightgrey;

    color: #fff;
  }
  .assigndata-table {
    .table {
      text-align: center;
      tr {
        td {
          padding: 10px 20px !important;

          &:first-child {
            text-align: left;
          }

          &:nth-last-child(-n + 3) {
            overflow: inherit;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 280px;
              display: inline-block;
            }
          }

          .fa-star {
            color: #d3d022;
            margin: 0 2px;
          }
        }
        th {
          padding: 10px 20px;
        }
      }
    }
  }
  .wrap-suggestion {
    padding: 0 10px;
    display: flex;
    .img-bx {
      img {
        margin-right: 20px;
      }
    }
    .user-details {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid;

      p {
        max-width: 400px;
      }
    }
    .content-para {
      padding: 15px;
      background: #1b2440;
      border-radius: 8px;
    }
  }
  .wrap-badgespg {
    .badges-content {
      display: flex;
      max-width: 450px;
      justify-content: space-between;
      padding: 10px 0 20px 0;
      margin-bottom: 20px;
      &:first-child {
        border-bottom: 1px solid;
      }
      .wrap-addmissiontabs li .active {
        background: #ff6871 !important;
        color: #fff;
      }
      .custom-content {
        .images-box {
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          svg {
            padding: 6px 5px;
            border-radius: 50%;
            background: rgb(58, 57, 57);
            font-size: 11px;
            color: #fff;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .user-task-btn {
    display: flex;
    gap: 30px;
  }
  .task-wrap {
    display: flex;
    align-items: center;
    gap: 1pc;
  }
  .task-heading {
    font-weight: bold;
    font-size: 16px !important;
  }
  .top-user {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 44px;
    margin-top: 2rem;
  }
  .facility-check input[type="checkbox"] {
    top: -5px;
    left: -30px;
    width: 16px;
    height: 16px;
  }
  .form-select {
    background-image: url(../../assets/images/subadmin-caret.png) !important;
  }
  .facility-check li {
    color: black !important;
  }
  .user-task {
    display: flex;
    gap: 40px;

    .user-img-task {
      background: black;
      width: 51px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      svg {
        color: white;
        font-size: 26px;
      }
    }
  }
  .btn-tl-id1 {
    background: aliceblue !important;
    color: #1585cf !important;
    font-size: 11px;
    border-radius: 3px;
    border: none;
  }
  .user-date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .date-to {
    background-color: #d4eeff;
    padding: 1rem;
  }
  .addoverview-data {
    background: #ffffff81;
    border: 1px solid lightgrey;
  }
  // .addoverview-data {
  //   background-color: #ffffd4 !important;
  // }
  .css-1fdsijx-ValueContainer {
    background-color: #1e274200 !important;
  }
  .css-1jqq78o-placeholder {
    grid-area: 1/1/2/3;
    color: hsl(0, 0%, 50%) !important;
  }
  .add-testimonial .rating {
    background-color: #00b8ff6b !important;
  }

  .user-facts {
    background: #8ed3db00 !important;
    border: 1px solid #e3dcdc;
    border-radius: 12px;
  }
  .basic_detail {
    background: #8ed3db00 !important;
    border: 1px solid #e3dcdc;
    border-radius: 12px;
  }
}
.subadmin-card span svg {
  color: rgba(0, 0, 0, 0.925) !important;
  font-size: 10px;
  background: #0af9f16e;
  padding: 5px 7px;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
}
.wrapper-assignpage .css-1jqq78o-placeholder {
  grid-area: 1/1/2/3;
  color: gray !important;
  font-size: 11px;
  padding-left: 5px;
}
button.btn-cross.subadmin-cross {
  top: 37px;
}
.subadmin-check {
  input[type="checkbox"] {
    top: 4px !important;
    left: -30px;
    width: 16px;
    height: 16px;
  }
}
.btn-light {
  border: 1px solid skyblue;
  padding: 4px 17px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 300;
  margin: 5px 0px;
}

.subadmin-placement-card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: 0px 10px;
  border-radius: 20px;
}
