.body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: "Poppins";
  word-break: break-word;
}

.html_string h3 {
  word-break: break-word;
  // font-size: 1.17rem;
}

.html_string h4 {
  word-break: break-word;
  // font-size: 1rem;
}

.third-info {
  width: 140px;
}
.fixTableHead {
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 20px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px;
  background-color: transparent !important;
}
th {
  background: #191c36 !important;
  position: relative;
  z-index: 1;
}
p,
li,
span {
  color: #ffffff;
  font-size: 16px !important;
  word-break: break-word;
  font-family: "Poppins";
  @media (max-width: 1640px) {
    font-size: 14px !important;
  }
}
.ad-intro {
  display: flex;
  justify-content: space-between;
}
.no-data {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo {
  text-align: center;
}

.react-confirm-alert-body h1 {
  color: #000 !important;
  font-size: 20px;
  margin: 10px 0px;
  font-family: "Poppins";
  @media (max-width: 1640px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }
}

h1:not(.html_string h1, .react-confirm-alert-body h1),
h2:not(.html_string h2),
h3:not(.html_string h3),
h4:not(.html_string h4),
h5:not(.html_string h5),
h6:not(.html_string h6) {
  // word-break: break-all;
  color: #e19c28 !important;
  font-size: 20px;
  margin: 10px 0px;
  font-family: "Poppins";
  @media (max-width: 1640px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  // @media(max-width:1366px) {
  //     font-size: 14px;
  // }
}

.form-select {
  background-image: url(../../../src/assets/images/caret-down.png);
  background-repeat: no-repeat;
}

.form-check-input {
  background-color: transparent;
}

label {
  font-size: 16px !important;
  font-family: "Poppins";
  @media (max-width: 1640px) {
    font-size: 14px !important;
  }

  @media (max-width: 1440px) {
    font-size: 12px !important;
  }
}

.place-logo {
  position: relative;

  .btn-cross {
    position: absolute;
    left: 135px;
    top: 26px;
    font-family: "Poppins";
  }
}

.top-btn {
  position: relative;

  .btn-cross {
    position: absolute;
    left: 135px !important;
    top: 26px !important;
  }
}

.chip-exam {
  display: inline-block;
  gap: 20px;

  .css-w66kx-MuiChip-root,
  .css-1q5nkkt-MuiButtonBase-root-MuiChip-root {
    background: #db4a4a !important;
    color: white !important;
    padding: 7px 20px;
    border-radius: 20px;
    margin: 4px;

    .MuiChip-deleteIcon {
      color: white !important;
      font-size: 12px;
    }
  }
}

th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  color: #ffffff83;
  font-size: 18px;
  padding: 10px;
  text-align: justify;
  font-family: "Poppins";
  @media (max-width: 1640px) {
    font-size: 16px;
  }

  @media (max-width: 1440px) {
    font-size: 15px;
  }
}

input[type="file"]::file-selector-button {
  background-color: #f2f2f89d;
  transition: 1s;
  font-weight: 500;
  font-family: "Poppins";
}

input[type="file"]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
  font-family: "Poppins";
}

.table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  text-align: center;
  background-color: #1d1742ba;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  font-family: "Poppins";
  td {
    font-family: "Poppins";
    text-align: justify;
    word-break: break-all;
    p {
      border-radius: 4px;
      font-family: "Poppins";
      text-align: justify;
      margin: 10px;

      // font-weight: bold;
    }
  }

  .facility-check {
    ul {
      li {
        margin: 0;
        font-family: "Poppins";
      }
    }
  }
}

.facility-check input[type="checkbox"]:checked {
  background-image: url("../../assets/images/checked-box.svg");
}

.facility-check input[type="checkbox"]:not(:checked) {
  background-image: url("../../assets/images/unchecked-box.svg");
}

.facility-check li {
  display: inline-block;
  position: relative;
  top: 1px;
  font-family: "Poppins";
  @media (max-width: 1640px) {
  }
}

.user-name,
.user-position {
  word-break: break-word;
}

.discard {
  background: #32de8a !important;
}

.editor {
  margin: 10px 0px;
}

.text_black {
  color: black !important;
}

.nodata {
  svg {
    width: 300px !important;
  }
}

.input-field-error {
  color: #ff6871;
  font-size: 12px;
  margin-top: 5px;
  text-transform: capitalize;
}

.date-to {
  background-color: #1d1742ba;
  border-radius: 4px;
}

.overview {
  .subheader-right-btm {
    margin-top: 0;
  }
}

a.tab-links {
  cursor: pointer;

  img {
    margin-right: 10px;
    width: 15px;
  }
}

.college-list {
  .facility-check input[type="checkbox"] {
    top: -8px;
    left: -16px;
    width: 16px;
    height: 16px;
  }
}

.about-college {
  width: 50%;
}

.facility-check input[type="checkbox"] {
  display: block;
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-appearance: none;
  outline: 0;
  position: absolute;
  top: 5px;
  left: -37px;

  @media (max-width: 1640px) {
    top: 5px;
    left: -30px;
    width: 16px;
    height: 16px;
  }
}

.select__control.css-13cymwt-control {
  background-color: #1e2742 !important;
}

.css-1nmdiq5-menu {
  z-index: 1 !important;
  position: relative !important;

  transition: all 0.3s ease-in-out !important;
}

.check-div {
  border-left: 1px solid lightgrey;
  max-height: 276px;
  overflow-y: scroll;
  h6.module-heading {
    background: #d4eeff;
    color: #252424cf !important;
    font-size: 13px !important;
    padding: 10px 15px;
    margin: 0 !important;
  }
  span {
    font-size: 10px;
  }
}

.check-btn {
  background-color: #2e8d3117 !important;
  border: 1px solid #2e8d31;
  color: #2e8d31;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 8px;
  height: 50px;
}

.table-main {
  display: block;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparentize rgba(94, 85, 85, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparentize rgba(148, 144, 144, 0.2);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.year_toggle {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  color: white;

  label {
    margin: 0px;
  }

  select {
    background: #050e29;
    padding: 0;
    font-size: 12px;
    color: #f4f7f5;
    border: none;
  }

  .my_toggle {
    padding: 0px 20px;
  }
}

.pagination .active a {
  color: #061239 !important;
  padding: 5px 10px;
  background-color: #f7f8fb;
  border-radius: 10px;
  margin: 0px 10px;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  .previous,
  .next {
    a {
      color: white;
      text-decoration: none;
      background-color: #061239;
      padding: 5px 10px;
      font-size: 13px;
      border-radius: 10px;
    }
  }

  a {
    color: white !important;
    text-decoration: none;
    background-color: #061239;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 12px;
    margin-right: 10px;
  }
}

.dashboard {
  text-align: left;
}

.chip {
  width: auto;
  height: auto;
  font-size: 12px;
  line-height: 32px;
  border-radius: 25px;
  text-transform: capitalize;
  color: #f3f0f0;
  padding: 0px 10px;
  font-weight: 600;
  background: #db4a4a;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .closebtn {
    padding: 7px;
    color: #fff;
    float: right;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    width: 10px;
    border-radius: 50px;
  }
}

th {
  color: #e19c28 !important;
}

tr.table-heading {
  &:hover {
    box-shadow: none !important;
  }
}

.rating-start {
  display: flex;
  width: 120px;
}

span.MuiRating-root.MuiRating-sizeMedium.css-iljtu2-MuiRating-root {
  width: 185px;
}

.table-default-dark {
  border-collapse: separate;
  border-spacing: 0 5px;

  tr {
    border: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      -webkit-box-shadow: 0px 0px 20px 0px rgba(78, 194, 240, 1);
      -moz-box-shadow: 0px 0px 20px 0px rgba(78, 194, 240, 1);
      box-shadow: 0px 0px 20px 0px rgba(78, 194, 240, 1);
    }

    th,
    td {
      color: $color-white;
      padding: 15px 0px;
      border: 0;
      vertical-align: middle;
      text-align: center;
      @media (max-width: 1440px) {
        padding: 10px 10px;

        .mb-3 {
          margin-bottom: 0 !important;
        }
      }
    }

    td {
      .on_off_toggle .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: -6px;
        right: 0;
        bottom: 0;
        background-color: #32de8b33;
        transition: 0.4s;
        border-radius: 36px;
        height: 18px;
        width: 30px;
      }

      .on_off_toggle input:checked + .slider {
        background-color: #32de8a;
      }

      .on_off_toggle {
        input:checked + .slider:before {
          transform: translateX(12px);

          .on_off_toggle input:checked + .slider {
            background-color: #32de8a;
          }
        }

        .slider {
          &::before {
            height: 18px;
            width: 18px;
            left: 0;
            bottom: 0px;
          }
        }

        .switch {
          width: 24px;
          height: 12px;
        }

        .switch input {
          display: none;
        }
      }
    }

    td:nth-last-child(-n + 3) {
      overflow: inherit;
      max-width: 100%;
    }
  }

  @media (max-width: 1700px) {
    font-size: 15px;
  }

  @media (max-width: 1668px) {
    font-size: 14px;
  }
}

.college-info2 {
  padding: 6px 10px;

  .user-img2 {
    width: auto;
    position: inherit;
    left: 0;

    img {
      width: 70px;
      margin: 9px 0;
    }
  }
}

.college-info {
  display: flex;
  gap: 35px;
  margin: 30px 0px;
  color: white !important;
  background: #1e2742;
  width: 57%;
  position: relative;
  border-radius: 15px;

  @media (max-width: 1700px) {
    width: 67%;
  }

  h5 {
    font-size: 15px;
    color: #e19c28 !important;
    margin: 9px 0px;
  }

  p {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;

    @media (max-width: 1366px) {
      font-size: 14px;
    }
  }

  img {
    width: 105px;
    border-radius: 15px;
  }
}

.college-info-new {
  display: flex;
  gap: 30px;
  margin: 30px 0px;
  color: white !important;
  background: #1e2742;
  width: 80%;
  position: relative;
  border-radius: 15px;
  padding: 1%;

  @media (max-width: 1700px) {
    width: 98%;
  }

  h5 {
    font-size: 15px;
    color: #e19c28 !important;
    margin: 9px 0px;
  }

  p {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;

    @media (max-width: 1366px) {
      font-size: 14px;
    }
  }

  img {
    width: 105px;
    border-radius: 15px;
  }
}
.multi-select {
  input {
    color: white !important;
    background-color: #050e29 !important;
  }
}
.user-data {
  display: flex;
  align-items: center;
  position: relative;

  .user-img {
    margin-left: 0.5%;
    width: 90px;
    height: 80px;
    position: absolute;
    left: 0;
    // right: 1;
    img {
      width: 100%;
      height: 100%;
      z-index: 1000;
      position: relative;
    }
  }

  .user {
    width: 60px;
  }

  .userimgbx {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      height: 60px;
    }
  }
}
.no_wrap {
  white-space: nowrap;
}

.subheader-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.rating-start svg {
  color: #e19c28;
  font-size: 20px;
}

.heading-36 {
  @include default-heading;
  margin-bottom: 10px;
  color: white !important;
  font-size: 30px;
  font-family: inherit;

  &::after {
    content: "";
    display: block;
    border-top: solid 2px #f3eeee;
    width: 50%;
    height: 1px;
    position: absolute;
    top: 110%;
    z-index: 1;
  }
}

.heading-sub-admin {
  @include default-heading;
  color: #000 !important;
  &::after {
    border-top-color: #000;
  }
}

.heading-37 {
  @include default-heading;
  color: black;
  margin-bottom: 8px;
  text-decoration-style: dotted;
  background-image: linear-gradient(
    to right,
    #000 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 10px 3px;
  background-repeat: repeat-x;
  font-weight: bolder;

  @media (max-width: 1640px) {
    font-size: 29px;
  }

  &::after {
    content: "";
    display: block;
    border-top: solid 3px #0a0a0a;
    width: 94%;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0px;
  }
}

.breadcrumps {
  color: #e19c28 !important;
  cursor: pointer;

  .svg-inline--fa {
    margin: 0px 6px;
  }

  a {
    :hover {
      color: white;
      transition: all 0.4s linear;
    }
  }
}

.dimension {
  font-size: 10px;
  color: #f5444e;
  margin-left: 10px;
}

.play-icon {
  color: #e19c28 !important;
  margin-right: 6px;
  font-size: 10px;
}

.action_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.td_ellipse {
  //  word-break: break-all;
  max-width: 130px !important;
}
.table_moreText_hide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.subadmin-nav-tab {
  display: flex;
  gap: 10px;

  .button_admin {
    color: #faf7f7d9 !important;
    background: transparent;
    border: none;
    font-size: 14px;
  }
  .tabbing-button_admin.active {
    position: relative;
  }
  .tabbing-button_admin.active::after {
    content: "";
    position: absolute;
    background-color: #1585cf;
    width: 46%;
    height: 3px;
    bottom: 0px;
    left: 30%;
  }
  .tabbing-button_admin.active .button_admin {
    color: #fcf9f9;
    border: none;
    font-weight: bold;
  }
  .tab_info .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    color: #0f0e0ead;
    font-size: 14px;
  }
  .tab_info .dropdown-menu {
    background-color: #ffffff;
    color: black;
  }
  .tab_info .dropdown-menu a {
    color: #020202c9;
    font-size: 14px;
    &:hover {
      background-color: #94d1db85 !important;
    }
  }
}

.overview-btn {
  .btn-purple {
    display: flex;
    align-items: center;
    font-size: 12px !important;

    .svg-inline--fa {
      font-size: 11px;
    }
  }
}
.subheader-right-btm.btn-purple {
  border: none;
  background-color: #f5444e;
  color: #ffffff;
  font-size: 14px;
  outline: 0;
  padding: 5px 20px;
  text-align: center;
  border-radius: 30px;
  font-weight: 600;
  box-shadow: 0px 4px 4px #0000000a;
  padding: 7px 10px;
  margin-left: 7px;
  margin-left: 10px;
  border-radius: 48px;
  font-family: sans-serif;
}
.admission-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-btn {
  background: none;
  color: #ffb82b;
  border: none;

  &:hover {
    border: none;
  }
}

.delete-btn {
  background: none;
  color: #f5444e;
  border: none;

  &:hover {
    background: none;
    border: none;
  }
}

.common_heading {
  margin: 15px 0px;
  font-size: 20px;
  color: #e19c28;

  @media (max-width: 1640px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1366px) {
    font-size: 13px;
  }
}

.backtxt {
  display: block;
  margin-top: -40px;
  text-decoration: none;
  color: #000000c2;
  margin-bottom: 30px;
}

.footer {
  background-color: #1e2742;

  .row {
    --bs-gutter-x: 0;
  }

  .col-md-4 {
    h3 {
      font-size: 16px;
      color: #ffffffb7;
      padding: 15px 0px;

      @media (max-width: 1640px) {
        font-size: 14px;
      }

      @media (max-width: 1440px) {
        font-size: 12px;
      }

      span {
        margin: 0px 10px;
      }
    }
  }
}

.image-upload-input {
  display: none;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.previous_text {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subheader-right-btm {
    margin: 0 !important;
  }
}

.previous-paper {
  h4 {
    margin: 0;
    padding: 0;
  }
}

img.couselling-card {
  width: 150px;
  margin: 16px 0;
  border-radius: 22px;
  height: 125px;
}

.background-video {
  height: 100%;
  width: 100%;
  float: left;
}

p.word-break {
  // word-break: break-all;
}

.testimonial_card {
  background: #1e2742;
  border-radius: 15px;
}

.form-control:focus {
  color: white;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control:disabled {
  background-color: #1e2742;
  opacity: 1;
}

.react-confirm-alert-overlay {
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.37) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-button-group {
  display: flex !important;
  justify-content: center !important;
}

.react-confirm-alert-body {
  text-align: center !important;
  -webkit-box-shadow: 0px 0px 40px 3px #636768 !important;
  -moz-box-shadow: 0px 0px 40px 3px #636768 !important;
  box-shadow: 0px 0px 40px 3px #636768 !important;
  background-color: #9ca0a0 !important;
  h1 {
    color: rgb(7, 7, 7) !important;
  }
}

.my_toggle.cutoff {
  display: flex;
}

.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: #082746 !important;
}

.css-1dimb5e-singleValue {
  color: hsl(0, 0%, 100%) !important;
}

.css-166bipr-Input {
  color: hsl(0, 0%, 100%) !important;
}

.faq-select {
  width: 170px;
}

.col-md-2.image-plus.subheader-right-btm {
  background: #327dde54;
  margin: 0 !important;
  border-radius: 12px;
  width: 150px;
  height: 150px;
  justify-content: center;
  position: relative;
  margin-left: 15px !important;

  button {
    background: none !important;
    margin: 0 !important;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-cross {
    position: absolute;
  }
}

.college-image {
  position: relative;

  .btn-cross {
    left: 255px;
    top: 25px;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
  }
}

.image-upload {
  border-radius: 12px;
}

label {
  font-size: 16px;
  color: #e19c28 !important;
  display: block;
  margin: 2px 0px;
  // width: 200px;
}

.user-img.test-monial {
  left: 0;

  p {
    margin-bottom: 1rem !important;
  }
}

.permissions {
  label {
    margin: 0;
    margin-top: 5px;
  }
}

.select__input {
  color: white !important;
}

// .select__control--menu-is-open{
//   width: 100px!important;
//   margin-top: 400px;
//   margin-left: 100px;
//   .select__menu{
//     margin-left: 100px!important;
//   }
// }

// .css-9jq23d {
//     color: #fff !important;
// }
.css-1hb7zxy-IndicatorsContainer {
  align-items: baseline !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #202b48d5;
}

.nav-link {
  color: white;
}

// Css for edit permission prefrences model for checkbox
.checkbox-margin {
  margin-top: 0px !important;
  margin-left: 26px;
  margin-right: 10px;
}

.margin-inall {
  margin-right: 15px !important;
}

// subadmin color black
.subadmin-text-color {
  color: #000 !important;
  cursor: pointer;
}

.text-color-black {
  color: #000 !important;
}

// subadmin footer
.pointer-footer {
  cursor: pointer;
}

.my-5 {
  margin-bottom: 10rem;
}

/* CustomSelectStyles.css */

.custom-select .css-yk16xz-control {
  /* The main input container */
  border-color: "pink";
  cursor: pointer;
  /* Change the cursor style */
}

.custom-select .css-1wa3eu0-placeholder {
  color: "yellow";
  /* Change the placeholder color */
}

.custom-select .css-1uccc91-singleValue {
  color: "red";
  /* Change the selected text color */
}

.custom-select .css-g1d714-ValueContainer {
  /* The container for selected values */
  color: "green";
  /* Change the selected values text color */
}

.custom-select .css-1hb7zxy-Input {
  /* The actual input field */
  color: "brown";
  /* Change the input text color */
}

/* Additional styles as needed */

.tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
}

.year-color {
  font-size: 18px;
  cursor: pointer;
}

.highlighted-date {
  background-color: #ad6db140 !important;
  color: #333 !important;
  border-radius: 10% !important;
  font-weight: bold !important;
}

.card-stamp-main {
  padding: 10px 18px 0px 18px;
  background-color: #d4eeff3b;
  .card-subtitle {
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.card-style {
  background: #5498ff94;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px !important;
    font-weight: bold;
  }
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label {
  color: black;
}
.clgName {
  color: black;
  font-size: 12px !important;
}
.blue-p {
  color: #1585cf !important;
  font-size: 12px !important;
  margin-top: 4px !important;
}
/* width */
.check-div::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.check-div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

.subadmin-card {
  margin-bottom: 1rem;
}

/* Handle */
.check-div::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
.check-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// for testimonial
.custom-input-style input[type="text"] {
  color: #ffffff !important;
}

// static content
.nav-tab-sidebar {
  display: inline;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  list-style: none;
  .tabbing-button {
    button {
      border: none;
      background: none;
      color: #ffffff9d;
      font-size: 16px;
      margin-bottom: 40px;
      @media (max-width: 1640px) {
        // font-size: 14px !important;
      }
    }
  }
}

.tabbing-button.active-static button {
  color: #e19c28;
  // border-bottom: 2px solid #e19c28;
  padding-bottom: 10px;
  font-weight: bold;
}
.new {
  position: relative;
  .new-btn {
    position: absolute;
    background: green;
    color: white !important;
    font-size: 10px !important;
    left: 0;
    top: -3px;
  }
}

.color-black,
.color-black p,
.color-black h2 {
  color: #000;
}

.color-black h2.heading-36 {
  color: black !important;
}

.cursor-point {
  cursor: pointer;
  text-align: center !important;
  align-items: center;
  color: white;
  background-color: #32de8a;
  padding: 8px;
  border-radius: 10px;
}

.feature {
  display: flex;
  flex-direction: column !important;
}

.table_tr {
  background-color: transparent !important;
}

.gallery_img {
  width: 120px;
  height: 120px;
}

.video-picker {
  padding-bottom: 12px;
}

.video-bx {
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-bx.default-border {
  border-color: #ccc;
}

.video-bx.error-border {
  border-color: #f00;
}

.center-icon {
  font-size: 48px;
  color: #ccc;
}

.hidden-input {
  display: none;
}

.video-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}
.text-black {
  color: #000000; /* Dot color */
}

.text-red {
  color: red;
}
.text-green {
  color: green;
}
