.header-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.bottom_userinfo {
  display: flex;
}

.botton_info {
  border-right: 1px solid #f3f0f085;
  padding: 0px 30px;
  text-align: center;
  .slider {
    left: 10px;
  }
  span {
    color: #32de8a;
  }
  .btn {
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 8px 10px;
  }
  .action_btn {
    margin-top: 15%;
  }
}
.course-heading {
  margin-top: 25px;
}

.botton_info:last-child {
  border: 0;
}

.course-overview h4 {
  margin: 22px 0px 15px 0px;
}
.heading_tr {
  .td_ellipse {
    max-width: 196px;
  }
}

.third_bottom {
  display: flex;
  margin-top: 30px;
  gap: 40px;
  background: #1e2742;
  padding: 10px 30px;
  border-radius: 4px;
}

.ace {
  width: 96px !important;
}

.exam_btn button {
  background: #ff6871;
  border: none;
  border-radius: 48px;
  font-size: 14px;
  width: auto;
  height: 38px;
  color: white;
  font-weight: 600;
  padding: 0px 10px;
}

.active_new {
  background: #32de8a !important;
}

.exam_btn {
  // display: flex;
  // gap: 18px;
  button {
    margin-right: 8px;
    margin-bottom: 10px;
  }
}

.exam__detail {
  margin-top: 46px;
}

.botton_info h4 {
  margin-bottom: 12px;
  color: #ffcf54b0;
}

.third-info {
  h4 {
    color: #ffcf54b0;
  }
}

.course_spe {
  margin-top: 25px;
}

.placement__highlight p {
  font-size: 20px;
  line-height: 48px;
}

.placement__highlight {
  margin: 30px 0px;
}

.placement_compny {
  display: flex;
  text-align: center;
  margin-top: 40px;
  gap: 30px;
}

.company_name p {
  margin-top: 23px;
  font-family: "WorkSans-SemiBold";
}

.placement_summary p {
  font-family: "WorkSans-SemiBold";
  line-height: 43px;
}

.summary__heading h4 {
  margin: 46px 0px;
}

.company_name img {
  width: 100px;
}

.branch_heading h4 {
  margin: 35px 0px;
}

.branch_heading p {
  line-height: 43px;
}

.placement__table p {
  background: #dedede;
  height: 21px;
}

.placement__table table {
  width: 100%;

  text-align: center;
  margin-top: 32px;
  background: #00054f05;
}

.placement__table.placed {
  width: 1187px;
}

.entrance_exm h4 {
  line-height: 43px;
}

.gender__n {
  font-family: "Worksans-Regular";
}

.cut-select {
  display: flex;
  height: 30px;
  margin-bottom: 25px;
  align-items: center;
  h4 {
    margin: 0;
  }
}

.cutoff__highlight table {
  text-align: center;
  border-radius: 4px;
  color: #fafbfd;
  font-family: "WorkSans-SemiBold";
}
.course_list {
  text-align: left;
}
.cutoff__highlight p {
}

.cutoff__highlight td {
  border-top: none;
}

.gender__f {
  margin-top: 44px;
}

.course__heading h4 {
  font-size: 23px;
  margin-bottom: 20px;
}

.course__heading h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.course__select select {
  text-transform: none;
  outline: 0;
  border: 1px solid #95a1bb54;
  color: #95a1bb;
  width: 496px;
  height: 46px;
  padding: 11px;
}

.course_selector {
  display: flex;
  gap: 60px;
  margin-top: 25px;
}

.course__select input {
  width: 496px;
  height: 46px;
  border: 1px solid #d9deea;
  color: #d9deea;
  padding: 10px;
}

.course__select h4 {
  margin-bottom: 16px;
}
.chip__btn {
  display: flex;
  gap: 13px;
}

.spec__header h4 {
  color: white;
  background-color: #00054f;
  width: 220px;
  height: 43px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
}

.course__specialization {
  margin-top: 33px;
}

.spec__detail {
  background: #00054f0d;
  padding: 2px 28px;
  border-radius: 4px;
  margin-top: 50px;
  height: 372px;
}

div.ck-editor__editable {
  min-height: 252px;
}

.para__heading {
  margin: 36px 0px;
}

h4.course__overview {
  margin-top: 75px;
}

.gender__neutral {
  margin-top: 88px;
}

.gender__form td input {
  border: 1px solid #d9deea;
  margin-top: 12px;
  height: 46px;
  width: 280px;
  padding: 10px;
  color: lightgrey;
}

.gender__form td {
  text-align: left;
}

.gender__form {
  width: 1400px;
  background: #00054f05;
  border-radius: 4px;
  margin-top: 30px;
  padding: 25px;
}

.gender__form td h3 {
  font-size: 16px;
}

.obc {
  display: flex;
  gap: 45px;
}

.obc__input input {
  width: 144px !important;
}

.spec__header h3 {
  margin-top: 30px;
}

.scholarship__intro p {
  line-height: 35px;
}

.scholarship__detail .faculty-list p {
  margin: 14px 35px;
  padding: 20px;
}
.faculty-list.sch__detail table {
  width: 88%;
  height: 162px;
}
.add___table {
  margin-top: 35px;
}
.add___table {
  margin-top: 35px;
  display: FLEX;
  gap: 40px;
  align-items: CENTER;
}
.add___table button {
  background: none;
  width: 116px;
  height: 38px;
  border-radius: 48px;
  font-size: 14px;
  font-family: "WorkSans-SemiBold";
}
.add-course {
  text-align: left;
  .form-select {
    font-size: 12px;
  }
  .addoverview-data {
    margin-bottom: 30px;
  }
  .course-sp {
    background: #46517081;
    border-radius: 6px;
  }
  .multiple-select-2 {
    background: #1e2742 !important;
    border-radius: 0.375rem;
    .css-1hb7zxy-IndicatorsContainer {
      align-items: baseline;
    }
    .select__input {
      color: #050505 !important;
    }
    .select__input-container {
      color: #070707 !important;
    }
    .css-1p3m7a8-multiValue {
      padding: 2px 10px !important;
    }
    .css-1xc3v61-indicatorContainer {
      padding: 6px 8px;
    }
    .css-wsp0cs-MultiValueGeneric {
      padding: 2px;
    }
  }
}
.css-13cymwt-control {
  min-height: 30px !important;
}
.css-t3ipsp-control {
  min-height: 30px !important;
}
