.bx-login{
    .otp-bg{
    
.otp-field{
    position: relative;
    white-space: nowrap;
    .otp-msg{
        position: absolute;
    }
    input{
        width: 90px;
        font-size: 20px!important;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        margin: 2px;
        border: 1px solid #ced4da;
        background:  #fff;
        font-weight: bold;
        color: #000;
        outline: none;
        transition: all 0.1s;
&:focus {
    border: 2px solid #ced4da;
}
@media (max-width:1800px) {
    max-width: 80px;
}
@media (max-width:1668px) {
    max-width: 70px;
}
@media (max-width:1440px) {
    max-width: 60px;
}
}
}
.resendcodetxt{
    text-align: left;
    margin-bottom: 20px;
    h4{
        color: #272727d9!important;

        a{
            color: #000;
            text-decoration: none;
        }
    }
}
    }
}
.bx-login{
.backtxt{
    @media (max-width:1440px) {

    font-size: 15px;
}
}
}
