.add-college{
    text-align: left;
    .subheader{
    display: flex;
    justify-content: space-between;
}
.col-md-4{
    margin-top: 10px;
}
.facility-check{
    margin-top: 10px;
}
}
.wrapadd-course{
.select__menu{
    display: block !important;
    z-index: 99!important;
    position: absolute!important;
    
    color: black!important;
}
}
.select__menu{
    color: black!important;
}