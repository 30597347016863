.wrap-counselling-page{
    .spacebottom{
        margin-bottom: 16px;
        p{
            text-transform: capitalize;
        }
    }
    .wrap-answerkey{
        padding: 10px 20px 10px 20px;
        background: #1e2742;
        border-radius: 8px;
    }
    .couselling-table{
    .table{
        vertical-align: middle;

        p{
            padding: 5px 10px;
        }

        img{
            width: 120px;
            height: 120px;
        }
    }
}
.couselling-card{
    background: #050e29;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
        img{
            width: 100%;
            height: 140px;
            border-radius: 8px;
            margin-bottom: 20px;
        }
   
}
}