.club-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;

.club-img {
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    margin: 0;
    font-size: 16px !important;
   
  }
}
img {
  width: 100px;
}
}
.announce{
    span{
        color: white;
        font-size: 10px;
    }
}
.carrer-chip {
    display: flex;
    gap: 11px;
}
.projects{
    .club-img {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        p {
          margin: 0;
          font-size: 14px !important;
         
        }
      }
      img {
        width: 100px;
      }
      .club-name{
        color: #E19C28;

      }
}
.experts-tab {
    display: flex;
    gap: 23px;
    button {
        background: transparent;
        border: none;
    }
    .tabbing-button.chip.active  {
        border-bottom: 0;
        padding: 0;
        background: #db4a4a;
        color: white;
        padding: 0px 10px;
    }
    .tabbing-button.chip {
        background: #db4a4aad;
        color: white;
    }
}
.user-deitail {
    
    p{
        margin: 0;
    }
}
.subadmin-user-detail{
  display: flex;
  gap: 10px;
}
.qacard{
  background-color: #1e2742b0;
  border-radius: 1rem;
  padding: 1rem;
  svg{
    color: #E19C28;
  }
}