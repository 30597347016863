.permissions{
    display: flex;
    justify-content: space-between;
    .form-check-input{
        border: 1px solid rgba(240, 248, 255, 0.582);
    }
    .form-check-inline {
        display: block!important;
    }
   
}