$theme-bgcolor: #1E2742;
$theme-bgcolor-2: #061239;
$purple-bg: #7765E3;
$pink-bg: #F5444E;
$bg-white: #ffffff;
$color-white: #ffffff;
$color-white: #ffffff;
$color-black: #000;
$color-green: #32DE8A;
$color-grey: #32DE8A;
$f-w-300: 300;
$f-w-500: 500;
$f-w-600: 600;
$f-w-700: 700;
$w-100: 100%;
$fs10: 10px;
$fs11: 11px;
$fs12: 12px;
$fs14: 14px;
$fs15: 15px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs22: 22px;
$fs24: 24px;
$fs26: 26px;
$fs28: 28px;
$fs30: 30px;
$fs40: 40px;



@mixin heading-one($fs: 40px, $tc: $color-white, $mb: 15px) {
    font-size: $fs;
    color: $color-white;
    font-weight: $f-w-600;
    margin-bottom: $mb;
}



@mixin theme-btn ($bg-clr: $theme-bgcolor-2, $btn-color: $color-white, $fs: 14px) {
    border: none;
    background-color: $bg-clr;
    color: $color-white;
    font-size: $fs;
    outline: 0;
    padding: 5px 20px;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
}
.discard-btn{
    margin-right: 10px;
}
@mixin remove-btn-hover {
    &:hover {
        background: transparent;
        opacity: 1;
    }

    &:focus-visible {
        background: transparent;
        opacity: 1;
    }

    &:active,
    &:first-child:active {
        background: transparent;
        opacity: 1;
    }

    &::after {
        display: none;
    }
}

@mixin control-hover($clr: #fff, $bgClr: #061239, $boxShadow: none) {
    &:focus {
        color: $clr;
        background-color: $bgClr;
        box-shadow: $boxShadow;
    }
}

@mixin font-small($fs: $fs15, $fc: $color-white) {
    font-size: $fs;
    color: $color-white;
}

@mixin font-very-small {
    @include font-small($fs12)
}

@mixin comman-label {
    font-size: $fs14;
    color: #0DBFF5;
    position: absolute;
    left: 20px;
    top: 10px;
    font-weight: lighter;


    @media(max-width: 1680px) {
        font-size: 12px;
        left: 15px;
    }

    @media(max-width: 1440px) {
        font-size: 11px;
    }
}

@mixin comman-input {
    background: transparent;
    height: 78px;
    padding: 35px 20px 10px 20px;
    font-size: $fs18;
    color: $color-white;
    border-radius: 12px;
    border: 1px solid $text-sky-blue;

    @media(max-width: 1680px) {
        font-size: $fs16;
        padding: 30px 15px 10px 15px;
        height: 65px;
    }

    @media(max-width: 1440px) {
        font-size: $fs14;
    }
}

@mixin comman-textarea {
    @include comman-input;
    min-height: 160px;

    @media(max-width: 1680px) {
        min-height: 120px;
        padding: 30px 15px 10px 15px;
    }
}



@mixin vertical-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin fsize-18 {
    font-size: 18px;
    letter-spacing: -0.2px;
    font-weight: $f-w-600;
}


@mixin placeholder-color ($color, $focus) {
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $color;
        opacity: 1;

        &:focus {
            &::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: $focus;
            }
        }
    }

    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;

        &:focus {
            &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: $focus;
                opacity: 1;
            }
        }
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;

        &:focus {
            &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: $focus;
                opacity: 1;
            }
        }
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color;


        &:focus {
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $focus;
            }
        }
    }
}

@mixin default-heading($mb: 25px, $fs: 36px ) {
    font-size: $fs;
    margin-bottom: 50px;
    color: $color-white;
    font-family: "sophia-cc",sans-serif;
    text-align: initial;
    text-transform: uppercase;
    position: relative;
       
}

@mixin close-btn($w: 30px,
    $h: 30px,
    $bp: 2px 1px,
    $bs: 57px,
    $bi: url('../../../../public/assets/images/sprite_icons.png') no-repeat,
    $bc: #F8EEEF) {
    background: $bi;
    width: $w;
    height: $h;
    background-position: $bp;
    display: inline-block;
    position: absolute;
    z-index: 1;
    right: -7px;
    border: 0;
    border-radius: 15px;
    top: -12px;
    background-size: $bs;
    background-color: $bc;
}

@mixin theme-hover {
    background: $color-white;
    color: $color-white;
}