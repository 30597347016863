.add-campuslife {
  text-align: left;
  h3 {
    margin: 20px 0px;
  }
  p {
    margin: 0;
  }
  .add-facility.row {
    background: #46517081;
    padding: 25px 20px;
    margin: 0;
    border-radius: 6px;

  
  }
}
.subheader-title {
  margin-bottom: 0px;
}

.medical.facility-check {
  background: #46517081;
  border-radius: 6px;
  h2{
    color: white!important;
    margin: 0;
    font-weight: 300;
    font-size: 13px;
    margin-top: 5px;
  }
  ul {
    max-width: 600px;
    li {
      margin-right: 60px;
      margin-top: 20px;
    }
  }
}
.album {
  background: #46517081;
  border-radius: 6px;
}

.campus-life{
  .accordion-button{
    display: flex;
    justify-content: space-between;
  }
  .facility-check li {
    display: inline-block;
    position: relative;
    top: 1px;
    padding-right: 55px;
}
.facility-check .disable-data{
  color: #b1a4a4;

   input[type=checkbox]:not(:checked) {
    filter: brightness(0.5);
}
}

.facility-check input[type=checkbox] {
    top: 2px;
    left: -30px;
    width: 16px;
    height: 16px;

}

}

.form-check-input:checked {
  background-color: transparent!important; 
    border-color: transparent!important;
 }
 .campus-life{
  .collegecompusimg{
      display: block;
     

    p{
      margin-top: 0px;
    margin-bottom: 10px;
    }
    .white-bg{
      background: none!important;
      padding: 0!important;
      margin-bottom: 15px;
       
  }
  .campus_img2{
    img{
      width: 100%!important;
    }
  }
}
  .white-bg{
    background: none!important;
    padding: 0!important;
     
    img{
      width: 100px!important;
      height: 100px!important;

    }
    
  }
 
  }
