.main-header {
    transition: all 0.3s ease-in-out;
    .dropdown-menu.show{
        right: 0;
        left: auto;
        background: #1e2742;
        max-width: 150px;
        .dropdown-item{
            color: #fff;
            &:hover{
                background-color: #050e29;
            }
        }
    }
    &.is-sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        backdrop-filter: blur(8px);
        background-color: rgba(30, 39, 66, 0.9);
        width: calc(100% - 345px);


        @media (max-width: 1800px) {
            width: calc(100% - 300px);

        }

        @media (max-width: 1680px) {
            width: calc(100% - 280px);
        }
        @media (max-width: 1440px) {
            width: calc(100% - 198px);
        }

        .subheader-search input {
            color: #fff;
            background-color: #061239;
            box-shadow: none;
        }

        .navbar {
            padding: 15px;
        }
    }

    .navbar {
        padding: 30px 15px;
    }

    .menu-btn {
        margin-right: auto;
        background: transparent;
        border: 0;
        @include remove-btn-hover
    }

    .header-nav-right {
        display: flex;
        align-items: center;

        .header-nav-items {
            display: flex;

            .btn-icon {
                margin-right: 40px;
                background: transparent;
                @include remove-btn-hover;
                border: 0;

                @media (max-width: 1680px) {
                    margin-right: 20px;
                }

                @media (max-width: 1440px) {
                    margin-right: 10px;
                }

                @media (max-width: 1199px) {
                    margin-right: 0px;
                }

                svg {
                    font-size: 26px;
                    color: $color-white;
                     
                    @media (max-width: 1680px) {
                        font-size: 20px;
                }
                @media (max-width: 1440px) {
                    font-size: 18px;
            }

                }
            }


        }

        .user-profile {
            button {
                background: transparent;
                border: 0;
                color: $color-white;
                ;
                display: flex;
                column-gap: 20px;
                align-items: center;

                img {
                    width: 55px;
                    height: 55px;
                    object-fit: cover;
                    border-radius: 10px;
                    overflow: hidden;

                @media (max-width: 1680px) {
                    width: 50px;
                    height: 50px;
            }
        }
                .user-deitail {
                    text-align: left;
                    color: $color-white;
                    p{
                        margin-bottom: 0;
                    }
                    @media (max-width: 1440px){
                        font-size: 14px;
                    }
                    
                }
            }
        }

    }

    .subheader-search {
        max-width: 280px;
        flex: 1;
        margin-right: 20px;

        input {
            height: 65px;
            border-radius: 40px;
            font-size: 18px;
            text-indent: 10px;
            @media (max-width:1700px){
                height: 55px;
                font-size: 16px;
            }
            @media (max-width:1600px){
                height: 45px;
                font-size: 14px;
            }
        }

        .search-icon {
            font-size: 26px;
            left: 16px;
            @media (max-width:1600px){
                font-size: 16px;
            }
        }
    }
}
.main-header .navbar{
    @media (max-width:1700px){
        padding: 20px 15px;
}
@media (max-width:1500px){
    padding: 15px;
}
}

.full-screen-layout{
    .main-header{
        &.is-sticky{
                width: calc(100% - 80px);
        }
    }
    li{
    a{
   display: flex;
   justify-content: center;
    span{display: none;}
    svg{
        margin-right: 0!important;
        width: 50px;
    }
}
}
}

