.wrapper_webinarpage {
p{
  font-size: 19px;
  color: #ffffff;
  opacity: 1;
  @media (max-width:1668px) {
    font-size: 17px;
    line-height: 24px;
  }
  @media (max-width:1440px) {
    font-size: 15px;
    line-height: 22px;
  }
}
.wrapper-placement{
  .chip{
    margin-right: 10px;
  }
    .college-info-card{
        display: flex;
        background: #1e2742;
    margin-bottom: 40px;
    align-items: center;
    padding: 18px 15px;
    border-radius: 20px;
    gap: 35px;
img {
    width: 85px;
}
    }
    .college-info{
        margin-bottom: 0;
        ul{
            padding: 0;
            margin-right: 10px;
        }
    }
    .webminar-topic{
        margin-bottom: 20px;
        padding: 15px 20px;
    background: #1b2440;
    img{
      width: 85px;
    }
    }
    //Optional css for this pen
.accordion{
  margin: 30px 0px;
}

// Main scss for this pen

.toggle {
    display: none;
  }
  
  .question-bx {
    position: relative;
    margin-bottom: 1em;
    background: #050e29;
  }
  
  .title,
  .content {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.2s;
  }
  
  .title {
    padding: 1em;
    display: block;
    font-weight: bold;
  }
   .title:before {
    position: absolute;
    right: 20px;
    top: 20px;
    transform: translate(-50% , 50% );
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .title:after {
    transform: rotate(180deg);
    right: 20px;
  }
  
  .content {
    max-height: 0;
    overflow: hidden;
    p {
      margin: 0;
      padding: 15px;
      font-size: 0.9em;
      line-height: 1.5;
    }
  }
  
  .toggle:checked {
   
    & + .title + .content {
      max-height: 500px;
    }
    & + .title:before {
      transform: rotate(180deg) !important;
    }
    & + .title:after {
        transform: rotate(-45deg) !important;
      }
  }
  
}
}
.college-detail{
  display: flex;
  gap: 25px;
  background: #1e2742;
  padding: 10px 15px;
  border-radius: 8px;
}
.webinar-img-bx{
  margin-left: 0px;
  margin-right: 0px;
  .card-img-bx{
   
    img{
      width: 100px;
      height: 100px;
      border-radius: 12px;
      object-fit: cover;
    }
  }
}
.webinar-area-img{
  img{
    width: 85px;
    margin-bottom: 20px;
    margin-right: 15px;
  }
}

.wrapper_webinarpage{
.tabs > input[type="radio"] {
  display: none;
}

.tabs > div {
  display: none;
  margin-top: 20px;
}

#tab-btn-1:checked ~ #content-1,
#tab-btn-2:checked ~ #content-2,
#tab-btn-3:checked ~ #content-3 {
  display: block;
}

.tabs > label {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    user-select: none;
    padding: 10px 33px;
    font-size: 16px !important;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    cursor: pointer;
    position: sticky;
    top: 110px;
    margin-bottom: 5px;
    background-color: #0C1C36;
}

.tabs > label:not(:first-of-type) {
  border-left: none;
}

.tabs > input[type="radio"]:checked + label {
  border-bottom: 2px solid #ffffff;
}
.tabs{
  .webinartab-leftcontent{
  .webinar-aside{
    height: calc(100% -  240px);
    position: fixed;
    overflow-x: auto;
    width: 260px;
    background: #1e2742;
    padding: 10px 15px;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;

    }
    &::-webkit-scrollbar:horizontal {
      height: 10px;
          border-radius: 10px;
  
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }

    .webinar-leftcontent{
      li{
        list-style-type: disc;
        margin-bottom: 15px;

        P{
          margin-bottom: 0;
          &:nth-child(1){
            font-size: 12px;
                      }
        }
        h6{
          margin-bottom: 10px;
        }
        .topelement-txt{
          padding: 10px 20px;
    line-height: normal;
    background: #1e2742;
    color: #ffffff;
    padding: 10px 15px;
    display: inline-block;
    border-radius: 24px;
        }
      }
    }
  }
  .is-sticky{
    
  }
}
.webinartab-rightcontent{
  margin-left: 300px;
}
}

}
.add-event{
  text-align: left;
  .addoverview-data.row{
    margin: 30px -10px;
  }
  .MuiRating-iconFilled {
    color: #E19C28;
    font-size: 30px;
   }
   .MuiRating-iconEmpty{color: white;font-size: 30px;}
   .css-dqr9h-MuiRating-label{
    font-size: 20px;
   }
    .MuiRating-sizeMedium{
    gap: 10px;
   }
   .rating{
    display: block;
    align-items: center;
    justify-content: space-between;
   }
}
.add-webinar{
  text-align: left;
  h6,h3{
    margin: 15px 0;
  }
  .webinar-info{
    background: #46517081;
    border-radius: 6px;
  }
  .question-ans,.speaker{
    background: #46517081;
    border-radius: 6px;
  }
}
.webinar-img{
  .col-md-2{position: relative;}
  .btn-cross {
    position: absolute;
    z-index: 1;
    left: 150px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: -9px;
    font-size: 11px;
}

}
.webevent-list{
  
  .nav-tab{
    justify-content: flex-start;
    gap: 20px;
  }
  .webinar-list{
    background: #1E2742;
    word-break: break-all;
    
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: white;
    background: none;
}
.nav-link {
  color: rgba(255, 255, 255, 0.63);
}
.webinar-heading{
  padding: 15px 0px 4px;
}
}
.webevent-list .nav-pills .nav-link.active {
  color: white;
border-right: 4px solid #E19C28;
background: #050e29;
border-radius: 8px;
 p{
  margin-bottom: 8px;
 }
}
.college-info-card{
  background: #1E2742;
  display: flex;
  border-radius: 10px;
  justify-content: space-around;
  position: relative;
  h6{
    color: #e19d28af!important;
  }
}
.webinar-detail{
  .row{margin: 0;}
}
.user-img.col-md-4.first-img img {
  width: 126px;
  height: 100px;
}
.user-img{
  width: 130px;
    position: absolute;
    left: 30px;
  img{
    border-radius: 10px;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}
.webinartab-rightcontent {
  word-break: break-all;
}
.event-info-card{
  justify-content: inherit;
  .artist-img-bx{
    position: inherit;
  }
  .artist-area{
    margin-right: 70px;
  }
}
.webinartab-rightcontent{
.web-detail{
  p{
    max-width: 100px;
    width: 70px;
  }
}
}