.scholarship {
  text-align: left;
  .table1 {
    width: 87% !important;
    th,
    td {
      width: 25% !important;
    }
  }
  .table {
    width: 60%;
    table {
      width: 100%;
      th,
      td {
        width: 50%;
      }
      th {
        font-size: 12px;
        padding: 10px 5px;
      }
    }
  }
}
.scholar {
  display: flex;
  align-items: center;
  gap: 20px;
  .subheader-right-btm {
    button {
      width: 110px !important;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
    }
  }
}
.add-scholarship{
  text-align: left;
  .subheader-right-btm{
    justify-content: flex-start;
    text-transform: capitalize;
    align-items: center;
    margin: 0;
    .btn-purple{
      width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  .other-scholarship{margin: 25px 0px;}
  .other-scholardetail{
    
    background-color: #1e2742b8;
    padding: 15px 20px;
    border-radius: 12px;
    .subheader-right-btm{
    justify-content: flex-end;
    }
  }
  .editor{
    margin: 25px 0px;
  }
}
.facility-check li {
  display: inline-block;
  position: relative;
  top: -8px;
}
