.wrap-add-campuslife{
    .student-textarea{
        background: none;
    }
    .album{
        background:none;
    }
    .uploadimgbx{
        .btn-purple{
            border: 0;
    color: #fff;

    svg{
        
    }
        }
        .btn-save{
            border-radius: 20px;
            padding: 5px 15px;
        }
        .addoverview-data{
            .btn-purple{
              
                height: 30px;
    display: flex;
    align-items: center;
    padding: 5px 9px;
    svg{margin-right: 7px;}
            }
        }
       
    }
    .addoverview-data{
        margin-bottom: 15px;
    }
    .add-facility{
        .align-center{
            text-align: center;
        }
    }
    .uploadimgbx{
    .program-events{
        .subheader-right-btm{
            max-width: 925px;
        }
    }
    .addoverview-data-imgbx2{
        position: relative;
        .btn-cross{
            left: 126px!important;
            line-height: 18px;
        }
    }
    
    }
.wrap-compus-imgbx{
    position: relative;

  .btn-cross{
    left: 144px;
    top: -3px !important;
    line-height: 18px;
    }
  } 
}
.wrap-compus-imgbx {
   
        .btn-purple{
        display: none!important;
        line-height: 18px;
    
    &:last-child{
        display: block!important;
    }

}
}
.wrap-entrancedate{
    .event-color{
        .btn-cross {
            left: 292px;
            top: 4px;
            padding: 0;
            line-height: 20px;
        }
    }
}
button.btn-cross.btn-cross1 {

    left: 135px!important;
    top: -3px !important;
  
    }