.subheader {
    .subheader-title {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}

    .css-1wy0on6 {
        -webkit-box-align: center;
        align-items: baseline;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
    }


.subheader-right-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.loader-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.subheader-right-btm {
  
       
        text-align: right;
        display: flex;
        justify-content: end;
    

    .btn-purple {
        @include theme-btn($pink-bg);
        box-shadow: 0px 4px 4px #0000000A;
        padding: 7px 10px;
        margin-left: 7px;
        margin-left: 10px;
        border-radius: 48px;
        font-family: sans-serif;
        svg {
            margin-right: 5px;
        }

        .disable-start {
            opacity: 0.6
        }
@media  (max-width:1440px) {
    padding:7px 10px;
}
    }
}

.add-btn {
    @include theme-btn;
    height: 47px;
    background-color: $theme-bgcolor;
    border-radius: 8px!important;

    @media (max-width: 1440px) {
        height: 37px;
        font-size: 12px;
    }
    svg {
        margin-right: 5px;
    }
}



.subheader-search {
    position: relative;
    min-width: 250px;
    margin-bottom: 0;
    margin-right: 15px;
    display: flex;
    align-items: center;

    input {
        width: 100%;
        padding: 8px 8px 8px 40px;
        height: auto;
        font-size: 14px;
        box-shadow: none;
        background-color: $theme-bgcolor;
        border-radius: 25px;
        border: 0;
        height: 47px;
        color: #ffffffc2;
        @include placeholder-color($color-white, $color-white);
        @include control-hover;

        @media (max-width: 1440px) {
            height: 37px;
            font-size: 12px;
        }
    }

    .search-icon {
        position: absolute;
        right: 0;
        left: 15px;
        color: #fff;
        width: 15px;
        bottom: 5%;
        transform: translate(0, -50%) scalex(-1);
    }
}
