.right-panel {
    padding-left: 345px;
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
    background: #050E29!important;
    background: #050E29 url('../../../../public/assets/images/rightpanel.svg')!important;
    background-repeat: repeat-y;
    background-blend-mode: overlay;

    @media (max-width: 1800px) {
        padding-left: 300px;
    }

    @media (max-width: 1680px) {
        padding-left: 280px;
    }

    @media (max-width: 1440px) {
        padding-left: 205px;
    }


    .subheader {
       
    }
    .subheader2 {
        display: flex;
    justify-content: space-between;
    align-items: center;
    
        @media (max-width: 1668px) {
            
        }
        @media (max-width: 1440px) {
            
        }
    }

    .right-panel-wraper {
        padding: 30px 25px;
        position: relative;
        z-index: 1;
    }

}

.full-screen-layout {
    .right-panel {
        padding-left: 80px;
    }
.fevicon{
  display: block;
  width: 45px!important;  
}
.logo{display: none;}
}
.fevicon{
    display: none;
   
  }