.experts-tabs{
    gap: 10px;
    display: flex;
    .chip{
        background: #db4a4a6b;
        button{
            background: transparent;
            border: none;
            color: white;
        }
        p{
            margin: 0;
        }
    }
    .chip.active {
        background: #db4a4a;

    }
    
}
.nav-tab.tabb{
    border-bottom: none!important;
   justify-content: flex-start;
   gap: 10px;
}
