.dash-loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 286px;
}
.dashboard-lottie {
  width: 100px;
}
.main-dash {
  border-radius: 20px;
  background: white;
  padding: 1.5rem 0.9rem;
}
.dash1 {
  background-color: #f1964d;
}
.dash2 {
  background-color: #79eb81;
}
.dash3 {
  background-color: #bc80cf;
}
.dash4 {
  background-color: #f5f55e;
}
.dash {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  img {
    width: 45px;
    padding: 11px;
  }
}
.card1 {
  background: #ffe8d6 0% 0% no-repeat padding-box;
  border: 1px solid #fcc192;
}
.card2 {
  background: #e4ffe6 0% 0% no-repeat padding-box;
  border: 1px solid #8afb92;
}
.card3 {
  background: #f5d4ff 0% 0% no-repeat padding-box;

  border: 1px solid #d0a9dc;
}
.card4 {
  background: #ffffd4 0% 0% no-repeat padding-box;
  border: 1px solid #f5f57f;
}
.card-title {
  margin-bottom: 5px !important;
}
.card-subtitle {
  font-size: 24px !important;
  margin-top: 0 !important;
  font-weight: 700;
}
.card {
  --bs-card-spacer-y: 0.4rem !important;
  --bs-card-spacer-x: 0.4rem !important;
  box-shadow: 2px 2px 6px #00000066;

  border-radius: 16px;
  opacity: 1;
  .card-title {
    font-size: 10px !important;
    text-align: center;
  }
}
.react-calendar__tile--active {
  background-color: #ffce54 !important;
  color: #333 !important;
  //   border-radius: 10% !important;
  font-weight: bold !important;
  //   width: 20% !important;
}
.react-calendar__navigation {
  background: #ffffd4;
  border-radius: 18px 18px 0px 0px;
}
button.react-calendar__navigation__arrow {
  border-radius: 10px;
}
.assign {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50px;
  }
  p {
    font-size: 10px !important;
  }
  h6 {
    font-weight: normal;
    font-size: 12px !important;
  }
}
.selected-data {
  display: flex;
  justify-content: space-between;
}
.graph-div {
  border-radius: 20px;
  padding: 20px;
  background: white;
}

.card-scroll {
  background-color: white;
  max-height: 310px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;

  border-radius: 20px 10px 10px 20px;
  margin-top: 13px;
}
.active-tasks {
  .bold {
    font-weight: bold;
  }
  .card-subtitle {
    font-size: 13px !important;
  }
  .deadline {
    gap: 10px;
  }
  .status {
    h6 {
      margin: 0 !important;
      font-size: 10px !important;
    }
  }
  .circle-dot {
    color: #79eb81;
  }
}
.subadmin-nav-tab.active-task .tabbing-button.active button {
  color: #1a1a1a;
  background: #d4eeff;
  padding: 5px;
  font-weight: bold;
  border-radius: 16px;
}
.year {
  border: none;
  background: no-repeat;
  font-size: 12px;
  select {
    font-size: 10px;
  }
}
.select-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.select-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.select-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.select-radio input:checked ~ .checkmark {
  background-color: #000000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.select-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.select-radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}
