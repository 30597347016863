.wrapper-assignpage{
    .wrapassign-subheader{
        .subheader-title{
            svg{
                margin: 0 10px;
            }
        }
    }
    .card-nav-tab{
        display: block;
    
    .task-detailcard{
        padding: 25px 25px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(128,123,128,1);
-moz-box-shadow: 0px 0px 12px 0px rgba(128,123,128,1);
box-shadow: 0px 0px 12px 0px rgba(128,123,128,1);
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    p{
        font-size: 25px!important;
    }
    }
}
.main-user{
    .user-profile{
        .profile-img{
            img{
                width: 100px;
            }
        }
        .user-txt{
            .btn-leaderid{
                background: #87ceeba1;
color: #fff;
margin-bottom: 10px;
            }
        }
    }
}
    .table-main{
tr{
    td{
        img{
            width: 40px;
        }
    }
}
    }
    .tabbing-button.active {
        .task-detailcard{
            background-color: #100a0aad;
           h1,p{
            color: #fff!important;
           }
        }
    }
}
.main-user.subadmin-main {
    background-color: none;
    background: none;
}

.subadminbread{
    h2{
        font-weight: bold;
    }
}