@import "./academics/indiancollege.scss";
@import "./fonts/font.scss";
@import "./variables/variables.scss";
@import "./comman//comman.scss";
@import "./utils/hoc/header.scss";
@import "./utils/hoc/sidebar.scss";
@import "./utils/hoc/hoc.scss";
@import "./academics/colleges.scss";
@import "./login/Login.scss";
@import "./academics/courselist.scss";
@import "./overview/overview.scss";
@import "./faq/faq.scss";
@import "./placement/placement.scss";
@import "./otp/otp.scss";
@import "./webinar/webinar.scss";
@import "./academics/ranking.scss";
@import "./academics/scholarship.scss";
@import "./academics/admission.scss";
@import "./academics/testimonial.scss";
@import "./lottie/lottie.scss";
@import "./addcollege/addcollege.scss";
@import "./academics/placement.scss";
@import "./academics/campuslife.scss";
@import "./academics/datetiremember.scss";
@import "./entranceexam/entranceexam.scss";
@import "./subadmin/subadmin.scss";
@import "./entranceexam/tipsexperts.scss";
@import "./entranceexam/previouspaper.scss";
@import "./scholarship/scholarship.scss";
@import "./career/career.scss";
@import "./subadminsidebar/subadmin.scss";
@import "./subadmin/subadminindianoverview.scss";
@import "./subadminassign/assigntask.scss";
@import "./subadmin/leaderprofile.scss";
@import "./assigntaskcollege/assigntaskcollege.scss";
@import "./assignreportchat/assignreportchat.scss";
@import "./assigncollegedetail/assigncollegedetail.scss";
@import "./collegedetail/collegedetail.scss";
@import "./addcompuslife/addcompuslife.scss";
@import "./entranceexam/resultcounselling.scss";
@import "./club/club.scss";
@import "./article/article.scss";
@import "./profiledetail/profildetail.scss";
@import "./dashboard/Dashboard.scss";
@import "./login/login2.scss";

.read-more-common {
  font-weight: 600;
  cursor: pointer;
  color: #e19c28 !important;
}

.placementImages {
  width: 10%;
  height: 55px;
  border-radius: 100%;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.selectData {
  width: 10%;
  margin-left: 5%;
  margin-right: 2%;
  background-color: #1d1742ba;
  border-color: "#ced4da";
  border-radius: "0.25rem";
  color: white;
}

.selectData1 {
  width: 10%;
  margin-left: 1%;
  margin-right: 2%;
  background-color: #1d1742ba;
  border-color: "#ced4da";
  border-radius: "0.25rem";
  color: white;
}

.pagination-container select {
  margin-right: 20px;
  margin-top: -25px;
}

.black-font {
  color: #000 !important;
}

.mainBody {
  padding: 28px;
}

.customEditor {
  padding-top: 15px;
}

.image {
  padding-top: 14px;
}

.main-body {
  margin-top: 4%;
}

.conclusion {
  padding-top: 4%;
}

.editor {
  padding-top: 2px;
}

.tags {
  padding-top: 10px;
}

.buttons {
  border-radius: 20px;
  margin-left: 10px;
  width: 150px;
}

.blogsDetails {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.AllText {
  color: #e19c28;
  font-size: 17px !important;
}

.Icon {
  height: 50px !important;
  width: 27% !important;
}

.Number {
  margin-left: 3px;
}

.mainImage {
  display: flex;

  gap: 5px;
}
.ptext2 {
  font-size: 13px !important;
}
.ptext3 {
  margin-top: -11px;
}
.imageWidth {
  height: 50px;
  border-radius: 100%;
  width: 25%;
}

.textHeading {
  display: flex;
  justify-content: space-between;
}

.headingText {
  display: flex;
  gap: 8px;
}

.CheckBox {
  cursor: pointer;
}

.SelectWidth {
  width: 25%;
  background-color: #1e2742;
  color: white;
}

.mainDiv {
  width: 100%;
  border-radius: 40px;
  background-color: #1e2742;
}

.coverImage {
  width: 40%;
  border-radius: 40px;
  margin-left: 30%;
}

.sectionDetail {
  margin-top: 4%;
}

.paragraph {
  padding-top: 0.5%;
}

.dropdown-no-caret .dropdown-toggle::after {
  display: none;
}
