 // Add Placement SCSS End Here
 .wrapper-placement{
    .viewdetails_text{
       
    }
    .placement-companyinfo{
       
    .companyinfo{
        display: flex;
         text-align: center;
         gap: 30px;
         padding: 0; 
         ul{
            padding: 0;
            margin: 0;
         }
         
         
        }
    }
    .year_toggle{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        
        label{
            margin: 0px;
        }
        select{
            background: #050E29;
            padding: 0;
            font-size: 12px;
            color: #32DE8A;
            outline: 0;
        }
        .my_toggle{
            padding: 0px 20px;
            color: #32DE8A;
        }
    }
    }
    
    .company-name{
        display: flex;
        gap: 30px;
    }
    .placement-card{
        background-color: #1d1742ba;
        padding: 10px 15px;
        border-radius: 20px;
        word-break: break-all;
    }
    
    






// Add Placement SCSS End Here