.employee-intro {
  .chip-exam {
    display: flex;
    gap: 20px;
    .css-w66kx-MuiChip-root {
      background: #db4a4a !important;
      color: white !important;
    }
  }
  .flow-img {
    margin: 20px 0 0 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: fill;
    }
  }
}
.application {
  a {
    color: white !important;
    text-decoration: underline !important;
  }
  .html_string h3 {
    word-break: break-word;
    font-size: 1.17rem;
  }
  h4 {
    word-break: break-word;
    font-size: 1rem;
  }
  h1 {
    word-break: break-word;
    font-size: 2em;
  }
  h2 {
    word-break: break-word;
    font-size: 1.5em;
  }
}

.alternative {
  img {
    width: 100%;
  }
}
.event-color {
  position: relative;
  background-color: #e19d284f;
  border-radius: 4px;
  .btn-cross {
    position: absolute;
    z-index: 1;
    left: 300px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: -5px;
    font-size: 11px;
    @media (max-width: 1800px) {
      left: 270px;
    }
    @media (max-width: 1700px) {
      left: 252px;
    }
    @media (max-width: 1600px) {
      left: 232px;
    }
    @media (max-width: 1400px) {
      left: 200px;
    }
  }
}
.add-date {
  background-color: #1d1742ba;
  border-radius: 6px;
}
.subheader-btn {
  float: right;
}
.addcollegeplacement-addcompany {
  .addentrance-facility {
    .addoverview-data.p-3 {
      padding: 0 !important;
    }
    .btn-cross {
      top: 5px !important;
      right: 5px !important;

      svg {
        margin-right: 0;
      }
    }
    .btn-purple {
      margin-top: 10px;
      border: 0;
      border-radius: 20px;
      color: #fff;
    }
  }
}
.maxwidhtdivbtn {
  max-width: 925px;
}
.remember-date {
  white-space: nowrap;
}
.remmeber-details-text {
  align-items: baseline;
  p {
    max-width: 85%;
    p {
      max-width: 100%;
      color: #fff !important;
      background: transparent !important;
      a {
        color: #fff !important;
        background: transparent !important;
        span {
          color: #fff !important;
          background: transparent !important;
          text-decoration-line: none !important;
        }
      }
      span {
        color: #fff !important;
        background: transparent !important;
      }
    }
  }
  .subheader-right-btm {
    align-items: baseline;
    .btn-cancel {
      background-color: #f5444e !important;
    }
    .btn-save {
      background: #32de8a !important;
    }
  }
}
.wrap-course-subject {
  display: flex;
  .course-sub-data {
    padding-left: 15px;
  }
}
.btn-add-subject {
  margin-top: 10px;
  border: 0;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 7px 10px;

  svg {
    margin-right: 5px;
  }
}
.addoverview-sub-data {
  background: transparent;
  .btn-cross {
    top: 7px !important;
    right: 5px !important;
    width: 18px;
    height: 18px;
  }
}
