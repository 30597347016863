.admission{
    text-align: left;
    table{
        width: 100%;
    }
    .heading_admission{
       
        display: flex;
        gap: 5px;
        align-items: center;
        
        
    }
    
    .user_skill{
        
        display: inline-flex;
        align-items: center;
        gap: 25px;
        cursor: pointer;
        .skill{
            background-color: #FF6871;
            color: white;
            border-radius: 50px;
            &.active {
                background-color: #32DE8A!important;   
                
            }
            p{
                margin: 0;
                margin: 0;
                padding: 8px 15px;
                max-width: 100px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .skill p:hover {
            max-width: 100% !important;
        }
    }
    .imp-date{
        .table{
            width: 60%;
        }
    }
    .admision_procedure{
        .table{
            width: 40%;
            
        }
}
.allocation{
    background: #1e2742;
    padding: 10px 15px;
    border-radius: 12px;
    
}
.selection_elg{
   
    .table{
        width: 50%;
        
    }
}


.admis_procedure{
    // background: #1e2742;
    padding: 10px 15px;
    border-radius: 12px;
}
.document_require{
    background: #1e2742;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 20px;
}
}
.wrap-addmissiontabs{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
li{
    list-style-type: none;
    .btn{
        background: #FF6871;
    color: #ffff;
    border: 0;
    border-radius: 20px;
    padding: 5px 20px;
    margin-right: 10px;
    width: max-content;
    &.active {
        background-color: #32DE8A!important;   
    }
    }
   
       
    }
}
.red-chip{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .active {

    }
}

.admission-tab{
    display: block;
}