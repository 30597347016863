.sidebar {
    background: $theme-bgcolor;
    position: fixed;
    width: 345px;
    height: 100%;
    top: 0;
    transition: all 0.3s ease-in-out;
    z-index: 50;

    @media (max-width: 1800px) {
        width: 300px;
    }

    @media (max-width: 1680px) {
        width: 280px;
    }
    @media (max-width: 1440px) {
        width: 212px;
    }
    
    .sidebar-header {
        padding-top: 30px;
            text-align: center;
        a {
            display: block;
            overflow: hidden;
            margin: 0 auto;
            padding-right: 20px 10px;
        }
        img{
           
                width: 160px;
            
        }
    }

    .sidebar-menu {
        margin-top: 30px;
        height: calc(100vh - 108px);
li{
    list-style-type: none;
}
        .sidebar-menu-list {
            padding: 0;
            list-style: none;
            margin: 0 14px 0 14px;
            @media (max-width: 1440px) {
                margin: 0 15px 0 10px;
            }

        }

        .sidebar-menu-list>li {
            


            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 20px 20px;
                border-radius: 21px;
                font-size: $fs18;
                color: $color-white;
                text-decoration: none;
                @media (max-width: 1800px) {
                    font-size: $fs16;
                    padding: 15px ;
                }
                @media (max-width: 1680px) {
                    font-size: 13px;
                    border-radius: 15px;
                    padding: 20px 20px;

                }


               

                .sidebar-menu-list-icon {
                    margin-right: 20px;

                    svg {
                        color: $color-white;
                    }
                    img{
                        width: 18px;
                    }

                }

                .sidebar-menu-list-txt {
                    display: flex;
                    flex: 1;

                    em {
                        font-size: 14px;
                        color: $color-green;
                        width: 30px;
                        height: 30px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $bg-white;
                        font-style: normal;
                        margin-left: 20px;
                    }
                }

                .sidebar-menu-list-submenu {
                    word-break: break-word;
                    padding: 5px;
                    color: $color-white;
                }
            }

            &.active>a {
                background: $color-green;
                padding: 20px 20px;

            }

            & .sub-menu {
                margin: 0px 0 0 20px;
                padding: 0;
                display: none;
                border-left: 1px solid #E19C28;

                li {
                    a {
                        padding: 8px 2px 8px 2px;
                        opacity: 0.4;


                    }

                    margin-bottom: 5px;
                }

                &.is-open {
                    display: block;

                    li {
                        
                            a.active {
                                color: white;
                                
                                opacity: 1;

                                svg {
                                    color: white;
                                    
                                }


                            }
                        }
                    }



                }
            }
        }
    }

.sidebar{
    .sidebar-menu{
        .sidebar-menu-list>li{
            a{
                .sidebar-menu-list-icon{

                @media (max-width: 1500px) {
                    margin-right: 10px;   
            }
        }
        padding: 15px 10px;
    }
    }
    .sidebar-menu-list>li.active>a{
        padding: 15px 20px;
        
}
}

}

.full-screen-layout {
    .sidebar {
        width: 80px;


        .sidebar-header {
            padding: 30px 0;

            a {
                width: 55px;
                padding-right: 0;
            }
            img{
                width: 215px;
            }
        }

        .sidebar-menu {
            li {
                a {
                    .sidebar-menu-list-txt {
                        display: none;
                    }
                }

            }
            
            
        }
        .sidebar-header{
            @media (max-width: 1700px){
                padding: 30px 0 10px 0;
            }
            @media (max-width: 1680px){
                padding: 25px 0 5px 0;
            }
                    }
    }
}

.tab-links.active {
    color: #E19C28;
    font-weight: bold;
    background: #ffffff17;

    svg {
        margin-right: 5px;
      path {
        fill: #E19C28;
      }
    }
}
.tab-links {

    svg {
        width: 25px;
        margin-right: 5px;
        
    }
}
.sub-menu{
.tab-links.active {
    color: #E19C28;
    background: none;
span{
    color: #E19C28;
}

    svg {
        margin-right: 3px;
      path {
        fill: #E19C28;
      }
    }
}
}
