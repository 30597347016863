.image-upload{
    position: relative;
    width: 150px!important;
    height: 150px!important;
}
.btn-cross{
    position: absolute;
    z-index: 1;
    left: 150px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 31px;
    font-size: 11px;
   
}
.btn-cros{
    position: absolute;
    z-index: 1;
    left: 148px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: -9px;
    font-size: 11px;
   
}
.right-btm{
    display: flex;
    justify-content: flex-start;
   
}
.subheader-right-btm {
    margin: 10px 0px!important;

}
.placement-editor{
    .col-md-9{
        width: 100%;
    }
}
.companyinfo{
    display: flex;
    li{
        list-style-type: none;
    }
}
.user-info{
    h6{
        color: #ffcf54b0;
        font-size: 13px!important;
        margin: 10px 0px;
    }
}
.images-gallery.row{
   .btn-cros{
    position: absolute;
    z-index: 1;
    left: 148px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 0px;
    font-size: 11px;}
        
}
.addcollegeplacement-addcompany{
    background: #46517081;
    padding: 20px;
    color: #000;
    position: relative;
    border-radius: 4px;

    .btn-cross{
        left: inherit;
        right: -9px;
    top: -4px;
    padding: 0;
    }
    .addoverview-data{
        label{
            color: #e19d28e0;
    margin: 10px 0px;
        }
    }
    
   } 
   .wrapadd-image-bx2{
    justify-content: end;
    button{
        border-radius: 48px!important;
    }
}
.headingwith{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

.user-bg{
    background: #1e2742;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
}

.subadmin-placement{
    .placement-card{
        background-color: transparent;
    }
}
