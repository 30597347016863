.name {
  font-size: 30px !important;
}

.color {
  color: #3ba5f4 !important;
}
.green {
  color: #84e18e;
  margin-right: 20px !important;
}
.social {
  padding: 10px 1rem;
  margin-top: 2rem;
  border: 1px solid black;
}
.profile{
    background-color: #cdefd1;
}
.user-h {
    font-size: 25px;
    font-weight: 400;
}
.basic-detail {
  padding: 10px 1rem;
  margin-top: 2rem;
  border: 1px solid black;
}
.grey {
  color: grey;
}
.black {
  font-weight: 700;
}
.about {
  p {
    line-height: 25px;
  }
}
.personal-detail {
  ul {
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 15px !important;
    li {
      color: black;
    }
  }
}
.user-ic {
  border: 1px solid black;
  border-radius: 20px;
  padding: 6px;
}
.profile {
    background-color: #cdefd1;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 10px;
    margin-top: 10px;
}