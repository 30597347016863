
th, td {
  padding: 0.25rem;
}
tr.red th {
  background: red;
  color: white;
}

.form-check-input {
  margin: 0;
}
.subadmin-sidebar .sidebar li {
  list-style-type: none;
}
th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  color: #ffffff83;
  font-size: 18px;
  padding: 10px;
  @media (max-width: 1640px) {
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    font-size: 14px;
  }
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
}
.right-panel.right-panel-subadmin {
  background-image: none !important;
  background-color: #f3f6ff !important;
  padding-left: 341px;
  padding-bottom: 250px;
  @media (max-width: 1640px) {
    padding-left: 240px;
  }
  
  label {
    font-size: 16px;
    @media (max-width: 1640px) {
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-size: 13px;
    }
  }
  .question-ans,
  .speaker {
    background: #d4eeff;
  }
  .subadmin-icon {
    svg {
    }
  } 

  .content_string , .content_string a {
    color: rgb(15, 14, 14) !important;
  }


  h1:not(.cour h1),
  h2:not(.cour h2),
  h3:not(.cour h3),
  h4:not(.cour h4),
  h5:not(.cour h5),
  li,
  .tabbing-button button,
  h6:not(.cour h6),
  .html_string a {
    color: rgb(15, 14, 14) !important;
  }
  .html_string p{
    color: rgb(15, 14, 14) !important;
    // font-size: 18px;

    @media (max-width: 1640px) {
      // font-size: 16px;
    }
    @media (max-width: 1440px) {
      // font-size: 13px;
    }
  }
  .user_detail h5, .cour h4 {
    color: #e19c28 !important;
  }
  .main-user h4 {
    color: #fff !important;
  }
  .wrap-answerkey {
    h1,h2,h3,h4.h5,p,li {
        color: #fff !important;
    } 
  }
  .wrap-answerkey {
    .heading_admission, .headingwithicon {
      color: #e19c28 !important;

    }
    .spacebottom {
      h1, h2, h3, h4, h5,h6,li {
        color: #e19c28 !important;
      };
      .html_string p{
        color: #fff !important;
      };
    }
  }
}
.cour h4 {
  color: #fff;
}
.subadmin-nav-tab {
  display: flex;
  gap: 10px;
  

  button {
    color: #1a1a1ad9 !important;
    background: transparent;
    border: none;
    font-size: 14px;
  }
  .tabbing-button.active {
    position: relative;
  }
  .tabbing-button.active::after {
    content: "";
    position: absolute;
    background-color: #1585cf;
    width: 46%;
    height: 3px;
    bottom: 0px;
    left: 30%;
  }
  .tabbing-button.active button {
    color: #1a1a1a;

    border: none;
    font-weight: bold;
  }
  .tab_info .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    color: #0f0e0ead;
    font-size: 14px;
  }
  .tab_info .dropdown-menu {
    background-color: #ffffff;
    color: black;
  }
  .tab_info .dropdown-menu a {
    color: #020202c9;
    font-size: 14px;
    &:hover {
      background-color: #94d1db85 !important;
    }
  }
}
.subadmin-sidebar {
  background-color: #1a1a1a;
  .sidebar-menu {
    margin-top: 0px;
  }
}
.privatepro {
  display: flex;
  margin: 20px 0px;
  gap: 15px;
  border: 1px solid #7070701c;
  border-radius: 20px;
  padding: 20px 12px;
  .proimg {
    svg {
      color: #1a1a1a;
      background-color: #1a1a1a1e;
      padding: 20px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
  }
}
.teamleaderpro {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 30px 0px;
  .team-img {
    svg {
      color: white;
      background-color: #1a1a1a;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 30px;
    }
  }
  .teamusertxt {
    span {
      background: #e0f0ec99;
      color: #1a1a1a;
      font-size: 10px;
      padding: 3px 6px;
    }
  }
  h1 {
    color: #0c1c36;
    margin: 8px 0px;
  }
  h2 {
    font-weight: 400;
  }
}
.menu {
  display: flex !important;
  justify-content: center;
  gap: 15px;
  align-items: center;
  .menu1 {
    width: 35px !important;
    @media (max-width: 1800px) {
      width: 20px !important;
    }
  }
}

.subadmin-header {
  background-color: #f9f9f9;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: end;
  height: 60px;
  .sidebar-menu {
    margin-top: 0px;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid black;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-menu.show {
    right: 0;
    left: auto;
    background-color: rgba(242, 243, 247, 0.9);
    max-width: 150px;
    .dropdown-item {
      color: rgb(5, 5, 5);
      &:hover {
        background-color: #040508 !important;
        color: white;
      }
    }
  }
  &.is-sticky {
    position: fixed;
    top: 0;
    z-index: 9;
    backdrop-filter: blur(8px);
    background-color: #f9f9f9;
    width: calc(100% - 345px);

    @media (max-width: 1800px) {
      width: calc(100% - 300px);
    }

    @media (max-width: 1680px) {
      width: calc(100% - 280px);
    }
    button.preference.change-btn {
      background: #be9e00;
      border: none !important;
      padding: 2px 10px !important;
      font-size: 10px !important;
      color: white !important;
    }
    @media (max-width: 1440px) {
      width: calc(100% - 239px);
    }

    .subheader-search input {
      color: #fff;
      background-color: #061239;
      box-shadow: none;
    }
    button.preference.change-btn {
      background: #be9e00;
      border: none !important;
      padding: 2px 10px !important;
      font-size: 10px !important;
      color: white !important;
    }
    .navbar {
      padding: 15px;
    }
  }

  .navbar {
    padding: 30px 15px;
  }

  .menu-btn {
    margin-right: auto;
    background: transparent;
    button.preference.change-btn {
      background: #be9e00;
      border: none !important;
      padding: 2px 10px !important;
      font-size: 10px !important;
      color: white !important;
    }
    border: 0;
    @include remove-btn-hover;
  }

  .header-nav-right {
    display: flex;
    align-items: center;

    .header-nav-items {
      display: flex;
      button.preference.change-btn {
        background: #be9e00;
        border: none !important;
        padding: 2px 10px !important;
        font-size: 10px !important;
        color: white !important;
      }

      .btn-icon {
        margin-right: 40px;
        background: transparent;
        @include remove-btn-hover;
        border: 0;

        @media (max-width: 1680px) {
          margin-right: 20px;
        }

        @media (max-width: 1440px) {
          margin-right: 10px;
        }
        button.preference.change-btn {
          background: #be9e00;
          border: none !important;
          padding: 2px 10px !important;
          font-size: 10px !important;
          color: white !important;
        }
        @media (max-width: 1199px) {
          margin-right: 0px;
        }

        svg {
          font-size: 26px;
          color: black;

          @media (max-width: 1680px) {
            font-size: 20px;
          }
          @media (max-width: 1440px) {
            font-size: 18px;
          }
        }
      }
    }

    .user-profile {
      button {
        background: transparent;
        border: 0;
        color: $color-white;
        display: flex;
        column-gap: 20px;
        align-items: center;
        button.preference.change-btn {
          background: #be9e00;
          border: none !important;
          padding: 2px 10px !important;
          font-size: 10px !important;
          color: white !important;
        }
        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 10px;
          overflow: hidden;

          @media (max-width: 1680px) {
            width: 40px;
            height: 40px;
          }
        }
        .user-deitail {
          button.preference.change-btn {
            background: #be9e00;
            border: none !important;
            padding: 2px 10px !important;
            font-size: 10px !important;
            color: white !important;
          }
          text-align: left;
          color: $color-white;
          p {
            margin-bottom: 0;
            color: rgb(8, 8, 8) !important;
          }
          @media (max-width: 1440px) {
            font-size: 14px;
          }
        }
      }
      button.preference.change-btn {
        background: #be9e00;
        border: none !important;
        padding: 2px 10px !important;
        font-size: 10px !important;
        color: white !important;
      }
    }
  }

  .subheader-search {
    max-width: 280px;
    flex: 1;
    margin-right: 20px;

    input {
      height: 65px;
      border-radius: 40px;
      font-size: 18px;
      text-indent: 10px;
      @media (max-width: 1700px) {
        height: 55px;
        button.preference.change-btn {
          background: #be9e00;
          border: none !important;
          padding: 2px 10px !important;
          font-size: 10px !important;
          color: white !important;
        }
        font-size: 16px;
      }
      @media (max-width: 1600px) {
        height: 45px;
        font-size: 14px;
      }
    }

    .search-icon {
      font-size: 26px;
      left: 16px;
      @media (max-width: 1600px) {
        font-size: 16px;
      }
    }
  }
}
.main-header .navbar {
  @media (max-width: 1700px) {
    padding: 20px 15px;
  }
  @media (max-width: 1500px) {
    padding: 15px;
  }
}

.full-screen-layout {
  .main-header {
    &.is-sticky {
      width: calc(100% - 80px);
    }
  }
  li {
    a {
      display: flex;
      justify-content: center;
      span {
        display: none;
      }
      svg {
        margin-right: 0 !important;
        width: 50px;
      }
    }
  }
}
.contactdt {
  h1 {
    color: #1a1a1a !important;
    font-weight: 400;
  }
}
.subadmin-footer {
  background-color: #1a1a1a;
  position: fixed;
  z-index: 61;
  bottom: 0;
  left: 0;
  right: 0;

  h3 {
    color: white !important;
  }
}

.col-md-6.footer-txt h3 {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  text-decoration: underline;
  align-items: center;
  color: #ffffffd1;
}
.col-md-6.footer-txt {
  text-align: right;
}

.subadmin-menu {
  padding-bottom: 120px !important;
  background-color: #1a1a1a;
}

.subadmin-sidebar-header {
  background-color: #1a1a1a;
  padding-top: 0 !important;
}
.logo1 {
  width: 255px !important;
  padding: 30px !important;
  padding-left: 0 !important;
  @media (max-width: 1800px) {
    width: 220px !important;
    padding: 22px;
  }
  @media (max-width: 1640px) {
    width: 173px !important;
    padding: 22px;
  }
  @media (max-width: 1540px) {
    left: 30%;
  }
}

.nav-tab.nav-tab-subadmin {
  display: flex;
  justify-content: flex-start;
  border-bottom: 0;
  gap: 20px;
  width: auto;
  button {
    color: black !important;
  }
  .tabbing-button.active {
    position: relative;
  }
  .tabbing-button.active button {
    border-bottom: 0;
  }
  .tabbing-button.active button:after {
    content: "";
    display: block;
    border-top: solid 3px #1585cf;
    width: 60%;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 25px;
  }
}

.subadmin-sidebar {
  .tab-links.active {
    background: #ffce5417;
  }
}
.tabs-button {
  display: flex;
  justify-content: end;
  gap: 10px;
}
button.preference {
  background-color: #e4ffe6;
  border: none;
  border-radius: 50px;
  padding: 9px 26px;
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: #000000 !important;
  @media (max-width: 1640px) {
    display: flex;
    gap: 4px;
    font-size: 12px;
  }

  img {
    width: 15px;
  }
}
.assign-college {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
}
.filter2 {
  background-color: #f5d4ff !important;
}
.filter3 {
  background-color: #ffffd4 !important;
}

.subheader-search.subadmin-search input {
  background: white;
  border: 1px solid lightgrey;
  height: 35px;
  @media (max-width: 1640px) {
    font-size: 10px;
  }
}

.subadmin-search ::placeholder {
  color: rgb(109, 106, 106) !important;
  opacity: 1;
}
.subadmin-search svg {
  color: rgb(109, 106, 106) !important;
  @media (max-width: 1640px) {
    width: 15px;
  }
}
.table-button {
  display: flex;
  justify-content: space-between;
  .bulk {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 40px 0px 25px 0px;
    font-weight: bold;
    @media (max-width: 1640px) {
      gap: 15px;
    }
    button {
      border: none;
      color: grey;
      border-radius: 50px;
      padding: 7px 25px;
      font-size: 15px;
      @media (max-width: 1640px) {
        padding: 5px 10px;
        font-size: 12px;
      }
      @media (max-width: 1440px) {
        font-size: 10px;
      }
    }
  }
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #070707;
  font-weight: 500;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #020202;
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #0d0e0d;
  width: 15px;
  height: 15px;
}
.table.subadmin-table {
  position: relative;
  background-color: transparent;
  color: black;
  border-bottom: 1px solid rgba(211, 211, 211, 0.432);
  tbody {
    background-color: aliceblue;
  }
  tr {
    border-radius: 10px;
  }
  td,
  tr {
    color: black;
    padding: 10px 5px;
    word-break: break-word;
    text-align: center;
    max-width: 130px;
    @media (max-width: 1640px) {
      font-size: 12px;
    }
    p {
      text-align: center;
    }
  }
  .subadmin-table-heading {
    background-color: #d4eeff;

    th {
      color: #000000 !important;
      font-weight: 500;
      text-align: center;
      padding: 10px;
      background-color: #d4eeff!important;
    }
  }
  tr:hover {
    box-shadow: none;
  }

  .status {
    color: #1585cf;
  }
  .publish {
    background-color: #d4eeff;
    border: none;
    border-radius: 50px;
    color: #252424;
    padding: 8px 15px;
    @media (max-width: 1440px) {
      padding: 8px 10px;
    }
  }

  .content::before {
    /* add the new bullet point */
    display: inline-block;
    content: "";
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    background-color: #6fe96f;
  }
  .intern::before {
    background-color: #e96fc4;
  }
  .tl::before {
    background-color: #e9d16f;
  }
  .publish-mode {
    color: #9700c5;
  }
  .permission {
    background-color: #fff0cc;
  }
}
aside.subadmin-sidebar.sidebar {
  width: 345px;
  @media (max-width: 1640px) {
    width: 261px;
  }
}
.subadmin-indian-college {
  .nav-tab.nav-tab-subadmin {
    justify-content: space-between;
    width: 100%;
  }
  .nav-tab.nav-tab-subadmin .tabbing-button.active button:after {
    content: "";
    display: block;
    border-top: solid 3px #1585cf;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0;
  }
  .nav-tab {
    margin-bottom: 0px;
  }
  .assign-college {
    margin-bottom: 10px;
  }
  .middle-header {
    display: flex;
    justify-content: space-between;
    button.preference {
      padding: 10px 15px;
    }
  }
  .approved {
    display: flex;
    border: 1px solid rgba(211, 211, 211, 0.411);
    border-radius: 48px;
    justify-content: center;
    width: 75%;
    align-items: center;
    p {
      padding: 5px 8px;
      margin: 0;
      color: black;
    }
    .active {
      background-color: #ffe8d6;
      font-weight: 600;
    }
  }
  .table-button .bulk {
    padding: 20px 10px;
  }
  .subheader-search.subadmin-search input {
    background: white;
    border: 1px solid lightgrey;
    height: 50px;
    @media (max-width: 1640px) {
      height: 45px;
    }
    @media (max-width: 1640px) {
      height: 35px;
    }
  }
  .actione-btn {
    display: flex;
    gap: 10px;
    .svg-inline--fa {
      padding: 8px;
    }
    .check {
      background-color: #e4ffe6;
      color: #009f05;
    }
    .close {
      background-color: #ffe4e4;
      color: #ff5757;
    }
    .pen {
      background-color: #1585cf18;
      color: #1585cf;
    }
  }
}
.bulk {
  p {
    color: #000000;
    margin: 0;
  }
}
.table-shadow:hover {
  box-shadow: 0px 0px 10px 0px #4ec2f0 !important;
  border-radius: 4px !important;
}
input.common-input {
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
  @media (max-width: 1740px) {
    font-size: 14px;
  }
  @media (max-width: 1640px) {
    font-size: 12px;
  }
  @media (max-width: 1440px) {
    font-size: 10px;
  }
}
.form-background {
  select {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
    @media (max-width: 1740px) {
      font-size: 14px;
    }
    @media (max-width: 1640px) {
      font-size: 12px;
    }
    @media (max-width: 1440px) {
      font-size: 10px;
    }
  }
  textarea {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
    @media (max-width: 1740px) {
      font-size: 14px;
    }
    @media (max-width: 1640px) {
      font-size: 12px;
    }
    @media (max-width: 1440px) {
      font-size: 10px;
    }
  }
}
.common-label {
  font-weight: 600;
  margin-bottom: 10px;
}

.form-background {
  background-color: #f9f9f9;
  padding: 40px 20px;
  select {
    background-image: url(../../assets/images/download.svg);
  }
}

.add-button {
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
  button {
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #000000;
    color: #000000;
    padding: 5px 20px;
    @media (max-width: 1600px) {
      font-size: 12px;
    }
  }
}
.adminplayicon {
  margin-right: 7px;
  font-size: 10px;
  color: #000000c9;
}

.hostel {
  label {
    color: #2e8d31 !important;
    font-weight: bold;
  }
  .form-switch .form-check-input {
    background-repeat: no-repeat;
  }
  .form-check-input:checked {
    background-color: #2e8d31 !important;
    border-color: #2e8d31 !important;
  }
}
textarea.form-control {
  resize: none;
  height: 80px;
}
.add-page-heading {
  h1 {
    margin: 0;
    padding: 0;
  }
}
.actione-btn {
  display: flex;
  gap: 10px;
  .svg-inline--fa {
    padding: 8px;
  }
  .check {
    background-color: #e4ffe6;
    color: #009f05;
  }
  .close {
    background-color: #ffe4e4;
    color: #ff5757;
  }
  .pen {
    background-color: #1585cf18;
    color: #1585cf;
  }
}
.subheader-title-subadmin {
  h2 {
    font-weight: bold;
    font-size: 18px !important;
  }
  .save-btn {
    background-color: #009a22 !important;
    border: none !important;
  }
  .cancel-btn {
    background-color: #ee5050 !important;
  }
}
button.btn-tl-id.btn-tl-id1 {
  background: aliceblue !important;
  color: #1585cf !important;
  font-size: 11px;
  border-radius: 3px;
}

.check-wrap {
  label,
  p {
    color: #000000;
    font-weight: 500;
  }
}
.save-btn {
  background-color: #009a22 !important;
  border: none !important;
}
.cancel-btn {
  background-color: #ee5050 !important;
}
.f-14 {
  font-size: 14px;
  text-align: left;
  color: #726f6f;
}
.subadmin-table {
  .td_ellipse {
    p {
      font-weight: 400 !important;
      margin: 0;
      padding: 0;
      text-align: left;
    }
  }
  .form-check-input:checked {
    background-color: #000000eb !important;
    border-color: transparent !important;
  }
}
.paginatipn {
  gap: 20px;
  display: flex;
  justify-content: end;
  button.prev-pegination,
  .Next-pegination {
    background: black;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    padding: 5px 13px;
    border-radius: 34px;
    &:hover {
      background-color: white;
      color: black;
      transition: all 0.4s linear;
      border: 1px solid;
    }
  }
}
button.preference.change-btn {
  background: #be9e00;
  border: none !important;
  padding: 2px 10px !important;
  font-size: 10px !important;
  color: white !important;
}
.subadmin-college-info {
  background: #d4eeff;
  p {
    color: #3c3434 !important;
  }
}
.subadmin-placement {
  label {
    font-weight: bold;
  }
  select {
    background-color: #faf8f5 !important;
    color: black !important;
    font-weight: 400;
    border: 1px solid lightgrey;
    background-image: url(../../assets/images/subadmin-caret.png);
    margin-top: 10px;
  }
  .subadmin-select {
    display: flex;
    align-items: center;
    height: 33px;
    select {
      border: none;
      background-color: transparent !important;
      font-size: 14px;
      margin: 0;

      outline: 0;
    }
  }
  table.table.subadmin-table.subadmin-table-heading {
    background-color: #d4eeff;
    p {
      background-color: white;
    }
  }
}
.select-tab {
  height: 50vh;
  h1 {
    font-size: 18px !important;
  }
}
.subadmin-faq {
  .subadmin-select {
    display: flex;
    height: 35px;
    select {
      border: none;
      font-size: 13px;
      background: transparent;
    }
  }
  .subadmin-faq-details {
  }
  .subadmin-form-select {
    background-image: url(../../assets/images/subadmin-caret.png);
  }
  .campus-life.faq_wrapper .accordion-item .accordion-button {
    background-color: #d4eeff;
    border-radius: 12px;
    color: #5fb6e9;
  }
  .campus-life.faq_wrapper .accordion-item {
    margin-bottom: 20px;
    background-color: #d4eeff75;
    border-radius: 12px;
    color: #515252;
  }
  .accordion-button::after {
    background-image: url(../../assets/images/subadmin-caret.png) !important;
    margin: 0;
  }
  .faq-icon {
    button {
      background-color: transparent;
      border: none;
    }
    svg.svg-inline--fa.fa-pen-to-square {
      color: #12cbc3;
    }
    svg.svg-inline--fa.fa-trash {
      color: #f1045a7a;
    }
  }
}
.subadmin-webinar-list {
  background-color: #d4eeff !important;
  .nav-pills .nav-link.active {
    border-right: 6px solid #28b6e1;
  }
}
.subadmin-card-bx {
  background-color: #f9fafa !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.507);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.432);
}
