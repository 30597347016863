.wrapper-assignpage{
    .wrapassign-subheader{
        display: flex;
        justify-content: space-between;
        align-items: center;
         
    }
    .selected-content{
        display: flex;
        margin-bottom: 20px;
        .inputgroup{
            display: flex;
            align-items: center;
            margin-right: 30px;

            input{
                margin-right: 15px;
            }
        }
    }
    .itemselected-bx{
        margin-bottom: 10px;
        select{
            background-color: rgb(250, 248, 245)!important;
            color: black!important;
            font-weight: 400;
            border: 1px solid lightgrey;
            background-image: url(../../assets/images/subadmin-caret.png);
            margin-top: 10px;
        }
        input{
            background-color: rgb(250, 248, 245)!important;
            color: black!important;
            font-weight: 400;
            border: 1px solid lightgrey;
            background-image: none;
            margin-top: 10px;
        }
        
           
        
    }
    .wrapckeditor-bx{
        margin-bottom: 20px;
    }
    .assigned-content-bx{
        .col-md-2{
            width: 18.666667%;
    .assigned-content{
        margin-bottom: 20px;

        p{
            margin-bottom: 10px;
        }
        button{
            border: 1px solid skyblue;
            padding: 4px 17px;
            border-radius: 20px;
            font-size: 13px;
            font-weight: 300;
        }
    }
    @media (max-width:1099px) {
        width: 20.666667%;
    }
    @media (max-width:991px) {
        width: 26.666667%;
    }
    @media (max-width:767px) {
        width: 32.666667%;
    }
    @media (max-width:640px) {
        width: 50%;
    }
}

    }
    input[type="date"]{
        position: relative;
    
    &::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 10px;
    }
    &::-webkit-datetime-edit-fields-wrapper {
        position: relative;
    }
    &::-webkit-datetime-edit {
        position: relative;
        left: 25px;
    }
}
.select-selected{
    position: relative;
    appearance: none;
    
}
.dcalender{
    position: relative;
    &::after{
        position: absolute;
        right: 10px;
        content: '';
        border: 4px solid transparent;
        border-top: 4px solid #000;
        margin-left: 2px;
        margin-bottom: 3px;
        display: inline-block;
        vertical-align: bottom;
    }
}
.wrap-action-btn{
    
    button{
       
    border-radius: 50%;
    margin: 0 5px;

   
    
svg{
    font-size: 12px;
}

    }
}
.eye-btn{
    border: 1px solid #ff6666!important;
    background: none;
    color: #ff6666;
}
.pagination{
    .active{
        color: #fff;
        
    }
    .pagination .active a {
        color: #061239 !important;
        padding: 5px 10px;
        background-color: #000000;
        border-radius: 10px;
        margin: 0px 10px;
    }
button{
   
    border-radius: 25px;
   
    border: 0;
   
    background-color: transparent;
    color: white;
    &:hover{
        color: #fff;
        background: transparent!important; 
    }
}
}
.tab-header{
    justify-content: left;
}
}


