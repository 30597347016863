// body{
//     padding:0;
//     margin:0;
//   }
.vid-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.bgvid {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  background: transparent linear-gradient(242deg, var(--secondary) 0%, #000000CC 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(242deg, #FFCE54 0%, #000000CC 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.7;
}

.logoNew {
  width: 256px;
  margin-top: 32px;
  margin-left: 49px;
  height: 90px;
  position: absolute;
}

.uniImage {
  height: 32px;
}

.inner-container {
  width: 440px;
  height: 500px;
  position: absolute;
  top: calc(50vh - 200px);
  left: calc(22vw - 200px);
  overflow: hidden;
  border-radius: 40px;
  backdrop-filter: blur(9px);
}

.loginText img {
  font-weight: 200;
  text-align: start !important;
  margin-left: 70px;
}

.loginText {
  margin-left: 70px;
}

.login-text {
  text-align: end;
  margin: 0px 70px 0px 0px;
}

.bgvid.inner {
  top: calc(-50vh + 200px);
  left: calc(-50vw + 200px);
  filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='10'%20/></filter></svg>#blur");
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  -o-filter: blur(10px);
  filter: blur(10px);
}

.box {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: Helvetica;
  color: #fff;
  background: rgba(0, 0, 0, 0.13);
  padding: 30px 0px;
}

.box h1 {
  display: block;
  width: 243px;
  margin: 11px 0px 11px 70px !important;
  padding: 0px;
}

.box input {
  display: block;
  width: 300px;
  margin: 20px auto;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  background: #F5F6F866 0% 0% no-repeat padding-box;
}

.box lable {
  display: block;
  width: 300px;
  margin: 0px auto;
  padding: 0px;
  color: #fff;
}

.box input:focus,
.box input:active,
.box button:focus,
.box button:active {
  outline: none;
}

.box button {
  // background: #FFCE54;
  background: var(--secondary) 0% 0% no-repeat padding-box;
  background: #FFCE54 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: 0;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  width: 330px;
  margin: 20px auto;
  display: block;
  cursor: pointer;
}

.box button:active {
  background: #FFCE54;
}

.box p {
  font-size: 14px;
  // text-align: center;
}

.box p span {
  cursor: pointer;
  color: #666;
}