// Add Faq SCSS Start Here
.wrapper_overviewpage{
    .college-info{
        display: flex;
        gap: 35px;
     .user-img{
        img{
            width: 80px;
        }
     }   
    }
    p{
        
    }
}
.accordion-body{
    word-break: break-all;
}
.wrapper-addfaq{
    .overview_studentdetails{
        .overview_studentdetails_bx{
            padding: 0;
        }
    }
    textarea::-webkit-scrollbar {
        width: 6px;
        border-radius:10px;
        background-color: #FFAD33;
}
    }
    .faq-icon{
        margin-left: auto;
        padding: 0px 16px;
        display: flex;
        .edit-btn,.delet-btn {
            background: none;
            color: #9e8044;
            border: none;
            margin-right: 8px;
        }
        .delete-btn:hover {
            
            border: none;
        }
    }
    .faq-data{
        text-align: left;
        .addoverview-data{
            display: flex;
            align-items: center;
            gap: 15px;
            h3{
                margin: 0;
            }
            .form-control{
                width: 140px;
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
  
    // Add Faq SCSS End Here