.subheader{
    .wrap-subheader-title{
        display: flex;
    flex-direction: inherit;
    align-items: center;
    .heading-36{
        margin-right: 40px;
    }
    .btn-select-assign{
        background: #1e2742;
    color: #fff;
    border-radius: 4px;
    border: 0;
    padding: 5px 15px 5px 5px;
    }
    }
}