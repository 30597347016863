.testimonial{
    
    .testimonial_card{
        background: #1e2742;
        border-radius: 10px;
        padding: 10px 15px;
        background: #1e2742;
        .likes,.dislikes{
           
            h1{
                margin: 11px 0px;
                color: #E19C28;
            }
        }
        .user_pro{
            display: flex;
            gap: 15px;
            p{
                margin: 0;
                
            }
            span{
                color: rgba(255, 255, 255, 0.582);
                
            }
        }
        .time_date{
            display: flex;
            justify-content: space-between;
        }
        .user_about{
            
        }
        
    }
}
.add-testimonial{
    text-align: left;
    
    input,select{
        width: 317px;
    }
    .review-area{
        textarea{
            height: 200px;
        }
    }
    .select-college{
        margin-bottom: 35px;
    }
    .overview_toggle{
        &::after{
            top: 70%;
     transform: none; 
    color: #95A1BB;
    left: 58%;
        }
    }
   .MuiRating-iconFilled {
    color: #E19C28;
    font-size: 18px;
   }
   .css-1c99szj-MuiRating-icon{color: white!important;font-size: 18px;}
   .css-dqr9h-MuiRating-label{
    font-size: 20px;
   }
    .MuiRating-sizeMedium{
    gap: 10px;
   }
   .subheader-right-btm {
    justify-content: inherit;
}
.d-flex.rating-img{
    gap: 15px;
    position: relative;
}
.rating-cross{
    position: relative;
    .btn-cross {
        position: absolute;
        z-index: 1;
        left: 122px;
        background: red;
        color: white;
        border: none;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        top: -5px;
        font-size: 11px;
        text-align: center;
    }
}
   .rating{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1E2742!important;
    border-radius: 10px;
    gap: 15px;
    padding-left: 0;
   }
   .like-dislike{
    margin-top: 20px;
    textarea{
        height: 150px;
    }
   }
   .filter-sort{
    .select-college{
        margin-top: 20px;
    }
   }
   .title{
    
        margin-top: 20px;
   
   }
   .voting{
    .overview_toggle::after {
        top: 70%;
        transform: none;
        color: #95A1BB;
        left: 27%;
    }
   }
   .answer{
    .facility-check input[type=checkbox] {
        top: 2px;
        left: -30px;
        width: 16px;
        height: 16px;
    }
   }
   .settings{
    margin-top: 20px;
   }
   .setting{
    background-color: #1E2742;
    width: 50%;
    padding: 10px 25px;
    border-radius: 10px;
    .check{
        color: green;
        border: 1px solid green;
        padding: 5px;
        border-radius: 50px;
    }
    .close{
        color: red;
        border: 1px solid red;
        padding: 5px;
        border-radius: 50px;
        width: 16px;
    height: 16px;
    }
    span{color: white;}
   }
   .icon{
    display: flex;
    align-items: center;
    gap: 20px;
   }
   .mutiple{
    margin: 20px 0px;
   
}
.subheader-testomnial-btn{
    .btn-cross{
        width: 20px;
        height: 20px;
        top: 4px;
        left: 130px;
        padding: 5px 6px;
    }
}

}
.css-13cymwt-control{
    border-style: none!important;
    background: none!important;
    border: none!important;
}
.css-1fdsijx-ValueContainer{
    background-color: #1E2742!important;
    border-radius: 12px;
    font-size: 14px;
    input{
        color: rgba(255, 255, 255, 0.973)!important;
    }
}
.css-1jqq78o-placeholder{
    color: white!important;
}
.heading_admission{
    display: flex;
    align-items: center;
    gap: 5px;
   
    
}
.date{
    margin-bottom: 5px;
    color: #ffb82b;
    font-size: 12px;
}
.MuiRating-iconFilled {
    color: #E19C28;
    font-size: 18px;
   }
   .css-1c99szj-MuiRating-icon{color: white!important;font-size: 18px;}
   .css-dqr9h-MuiRating-label{
    font-size: 20px;
   }
   .rating-header{
    text-align: right;
    display: flex;
   }
   .test-img{
    display: flex;
    gap: 15px;
    img{
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
   } 
}
.form-control.css-b62m3t-container{
    padding: 0px!important;
}
.css-t3ipsp-control{
    background-color: #1e2742!important;
    border: none!important;
    box-shadow: none!important;
}
// .css-1p3m7a8-multiValue{
//     font-size: 12px;
//     color: #353354 !important;
//     font-weight: 600;
//     background: #fbf8f8 !important;
//     border-radius: 10px;
//     padding: 5px 10px !important;
// }
// .css-wsp0cs-MultiValueGeneric{
//     color: rgb(31, 29, 42)!important;
// }