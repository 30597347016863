.wrapper-assignpage{
    .assigncolllege-subheader{
        .subheader-title{
            display: flex;
    flex-direction: inherit;
    align-items: center;
    .heading-30{
        font-size: 20px;
    }
button{
    background: #1e2742;
    color: #fff;
    border: 0;
    padding: 10px 20px;
    border-radius: 20px;
    margin-left:  15px;
}
        }
        .subheader-right-top{
            align-items: center;
            .wrap-option{
                display: flex;
                align-items: center;
                margin-left: 10px;
                input{
                    margin-right: 5px;
                }
            }
        }
    }
    .assigntask-nav-tab{
        justify-content: inherit;
        .tabbing-button{
            margin-right: 15px;
        }
    }
    .assigndata-table{
        .table{
            .green-dot{
                width: 10px;
                height: 10px;
                display: inline-block;
                background: green;
                border-radius: 50%;
                margin-right: 5px;
            }
            .yellow-dot{
                width: 10px;
                height: 10px;
                display: inline-block;
                background: rgb(131, 127, 2);
                border-radius: 50%;
                margin-right: 5px
            }
            .pink-dot{
                width: 10px;
                height: 10px;
                display: inline-block;
                background: rgb(137, 8, 72);
                border-radius: 50%;
                margin-right: 5px
            }
            .btn-permission4edit{
                background: rgb(131, 127, 2);
                border: 0;
            }
        }
    }
}