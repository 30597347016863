.ranking {
  text-align: left;
  .rank_heading {
    color: #32de8a;
  }
}
.subheader-right-btm {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  .btn-purple {
    height: 30px;
    display: flex;
    align-items: center;
  }
}
.wrap-paragraph {
  margin-bottom: 25px;
  p {
    span {
      background: transparent !important;
      color: #fff !important;
    }
  }
}

.bg-color {
  background-color: transparent !important;
}
// .w-space{
//     white-space: nowrap;
// }
