.add-date {
  text-align: left;
  .date-remem {
    background: #46517081;
    border-radius: 6px;
  }
}
.table {
  table {
    width: 100%;
    tr {
      background-color: transparent;
    }
  }
}
