.subheader-right-btm {
  margin: 0;
}
.college-info {
  display: flex;
  gap: 35px;
  margin: 30px 0px;
  color: white;
  background: #1e2742;
  width: 57%;
  padding: 0px 20px;
  border-radius: 15px;
  @media (max-width: 1700px) {
    width: 70%;
  }

  h5 {
    font-size: 16px;
    color: #ffcf54b0;
    margin: 9px 0px;
    @media (max-width: 1640px) {
      font-size: 14px;
    }
    @media (max-width: 1366px) {
      font-size: 12px;
    }
  }
  p {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;
    @media (max-width: 1640px) {
      font-size: 14px;
    }
    @media (max-width: 1366px) {
      font-size: 12px;
    }
  }
  img {
    width: 105px;
    border-radius: 15px;
  }
}
img.play {
  width: 10px !important;
  margin-right: 10px;
}
.main-user {
  background-color: #1e2742;
  padding: 15px;
  border-radius: 12px;
  color: white;
  h4 {
    color: white !important;
  }
  @media (max-width: 1440px) {
    padding: 10px;
  }
  .user-profile {
    display: flex;
    gap: 29px;
    align-items: center;
    line-height: 15px;
    h4 {
      font-size: 16px;
      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }
    p {
      font-size: 16px;
      color: #ffffffb8;
      @media (max-width: 1600px) {
        font-size: 12px;
      }
    }
  }
  .user_inform {
    margin-top: 15px;
    text-align: left;
    h4 {
      margin-bottom: 15px;
      font-size: 16px;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }
    p {
      color: #ffffffb8;
      @media (max-width: 1600px) {
        font-size: 13px;
      }
      @media (max-width: 1366px) {
        font-size: 11px;
      }
      span {
        margin-left: 30px;
        @media (max-width: 1700px) {
          margin-left: 15px;
        }
      }
    }
  }
}

.user-txt {
  h4 {
  }
}
.overview {
  .fact-students {
  }
  .content_approved {
    .user_approvedetail {
      display: flex;
      gap: 35px;
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
  }
  .about-img {
    max-height: 350px;
    display: flex;
    align-items: center;
    // justify-content: center;
    border-radius: 20px;
    img {
      width: 100%;
      max-height: 350px;
      height: 100%;
      border-radius: 20px;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    text-align: left;
    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }
  .university {
    text-align: left;
    p {
      color: #fff;
      font-size: 16px;
      display: block;
      display: -webkit-box;
      max-width: 100%;

      margin: 0 auto;
      font-size: 14px;
      line-height: 19px;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  h4:not(.html_string h4),
  h3:not(.html_string h3) {
    margin: 20px 0px;
    // font-size: 18px;
    text-align: left;
    @media (max-width: 1640px) {
      // font-size: 15px;
    }
  }
  .user_college {
    display: flex;
    gap: 50px;
  }
  .quick-facts {
    .user-facts {
      border-radius: 20px;
      background-color: #1d1742ba;
      padding: 20px 25px;
      .fact-details {
        display: flex;

        gap: 40px;
        p {
          line-height: 10px;
        }
      }
    }
  }
  .accommodation {
    display: flex;
    gap: 40px;

    ul {
      margin: 0;
      padding: 0;
      li {
        color: white;
        list-style-type: none;
      }

      .user-gender {
        border-right: 1px solid;
        ul {
          margin: 0;
          padding: 0;
        }
        li {
          display: inline-block;
          position: relative;
          top: 1px;
          display: flex;
          gap: 100px;
          color: white;
        }
      }
    }
  }
  .basic_detail {
    background-color: #1d1742ba;
    text-align: center;
    padding: 10px 25px;
    border-radius: 4px;
    h3:not(.html_string h3) {
      color: #ffcf54b0;
    }
    p {
      // word-break: break-all;
    }
  }
}
.courses-fees {
  table {
    width: 100%;
    td,
    th {
      color: #fff;
    }
    .on_off_toggle {
      position: relative;

      padding-right: 10px;
      .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 25px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }
  }
}
.on_off_toggle {
  position: relative;
  display: inline-flex;
  padding-right: 10px;
}

.on_off_toggle.table_toggle {
  padding-right: 0px;
}

.on_off_toggle p {
  display: inline-block;
  padding-right: 15px;
  color: #562db4;
  font-family: "Montserrat-Medium";
}

.on_off_toggle .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.on_off_toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.on_off_toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -6px;
  right: 0;
  bottom: 0;
  background-color: #32de8b33;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 36px;
  height: 18px;
  width: 30px;
}

.on_off_toggle .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 16px;
  left: 0px;
  bottom: 0px;
  background-color: #797e7b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100%;
}

.on_off_toggle input:checked + .slider {
  background-color: #32de8a;
}

.on_off_toggle input:focus + .slider {
  box-shadow: 0 0 1px #ffb000;
}

.on_off_toggle .slider.round {
  border-radius: 34px;
}

.on_off_toggle .slider.round:before {
  border-radius: 50%;
}

//  ###########  campus life ##########
.campus-life {
  .accordion {
    border-radius: 20px;
  }
  .accordion-item {
    background: #1e2742;
    border: none;
    color: white;
    text-align: left;
    border-radius: 20px;

    img {
      width: 150px;
      height: 120px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
      @media (max-width: 1640px) {
        font-size: 12px;
      }
    }
  }
  .accordion-button {
    background: #46517081;
    border: none;
    color: rgb(255, 255, 255);
    border-radius: 20px;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:not(.collapsed) {
      box-shadow: none;
    }
  }
  h3 {
    color: white;
    // font-size: 16px;
    text-align: left;
  }

  .college-img {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    .white-bg {
      background: #fff;
      padding: 30px;
      border-radius: 16px;
      img {
        width: 50px;
      }
    }
  }

  .ranking-table table {
    width: 70%;
    margin-top: 30px;
    color: white;
  }

  .ranking-heading {
    background: #000d2aad;
  }

  .architecture {
    background: #808080;
  }

  .pharmacy {
    background: #c8c8c8;
  }

  .ranking-table .table td,
  th {
    padding: 0.75rem;
    vertical-align: top;
  }

  .ranking-p p {
    font-size: 20px;
    font-family: "WorkSans-Regular";
    margin-top: 20px;
  }

  .courses-fees table {
    width: 100%;
  }

  .action_btns.fee-btn {
    justify-content: flex-start;
  }

  .courses-fees th,
  td {
    text-align: center;
    padding: 16px;
    color: #ffffff;
  }

  .courses-fees {
    margin-bottom: 30px;
  }

  .courses-fees tr {
    border-bottom: 1px dashed lightgrey;
  }

  /*page no 16 css*/
  .bottom_userinfo {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    width: 900px;
  }

  .botton_info {
    border-right: 2px solid lightgrey;
    padding-right: 58px;
  }

  .botton_info:last-child {
    border: 0;
  }

  .course-overview h4 {
    margin: 54px 0px 31px 0px;
  }

  .course-overview p {
    line-height: 40px;
  }

  .third_bottom {
    display: flex;
    width: 1260px;
    justify-content: space-between;
    margin-top: 60px;
  }

  .third-info p {
    color: #95a1bb;
    margin-top: 22px;
  }

  .ace {
    width: 96px !important;
  }

  .exam_btn button {
    background: transparent;
    border: 2px solid black;
    border-radius: 48px;
    font-size: 14px;
    width: 122px;
    height: 38px;
  }

  .exam_btn {
    display: flex;
    gap: 18px;
  }

  .exam__detail {
    margin-top: 46px;
  }

  .botton_info h4 {
    margin-bottom: 12px;
  }

  .course_spe .third_bottom {
    margin-top: 25px;
  }

  .course_spe {
    margin-top: 46px;
    background: #00054f0f;
    padding: 28px;
    border-radius: 4px;
  }

  .course__table p {
    background: #d8d8d8;
    height: 20px;
    margin: 4px 30px;
  }

  .course__table td {
    background: #00054f08;
    border-right: 1px solid lightgrey;
  }

  .course__table th {
    background: #00054f0f;
    text-align: center;
    border-right: 1px solid lightgrey !important;
  }

  .course__table table {
    margin-top: 60px;
    width: 1574px;
  }

  .course__table {
    margin-top: 40px;
    height: 435px;
  }

  .heading_tr {
    border-bottom: 1px solid lightgrey;
  }

  .placement__highlight p {
    font-size: 20px;
    line-height: 48px;
  }

  .placement__highlight {
    margin: 30px 0px;
  }

  .placement_compny {
    display: flex;
    text-align: center;
    margin-top: 40px;
    gap: 30px;
  }

  .company_name p {
    margin-top: 23px;
    font-family: "WorkSans-SemiBold";
  }

  .placement_summary p {
    font-family: "WorkSans-SemiBold";
    line-height: 43px;
  }

  .summary__heading h4 {
    margin: 46px 0px;
  }

  .company_name img {
    width: 100px;
  }

  .branch_heading h4 {
    margin: 35px 0px;
  }

  .branch_heading p {
    line-height: 43px;
  }

  .placement__table p {
    background: #dedede;
    height: 21px;
  }

  .placement__table table {
    width: 100%;

    text-align: center;
    margin-top: 32px;
    background: #00054f05;
  }

  .placement__table.placed {
    width: 1187px;
  }

  .cutoff__summary h4 {
    margin: 30px 0px;
  }

  .entrance_exm h4 {
    line-height: 43px;
  }

  .cutoff__summary p {
    line-height: 40px;
  }

  .gender__n {
    font-family: "Worksans-Regular";
  }

  .cutoff__highlight h4 {
    line-height: 40px;
  }

  .cutoff__highlight {
    margin-top: 40px;
  }

  .cutoff__highlight table {
    width: 1187px;
    text-align: center;
    background: #00054f08;
    border-radius: 4px;
    color: #0c1c36;
    font-family: "WorkSans-SemiBold";
    margin-top: 35px;
  }

  .cutoff__highlight p {
    background: #ececec;
    height: 27px;
    margin-top: 30px;
  }

  .cutoff__highlight td {
    border-right: 1px solid #d9deea;
    border-top: none;
  }

  th.td_left {
  }

  .cutoff__highlight th {
    border-right: 1px solid lightgrey;
  }

  .round {
    border-bottom: 1px solid lightgrey;
  }

  .cutoff__highlight td :last-child {
    border: none;
  }

  .gender__f {
    margin-top: 44px;
  }

  .course__heading h4 {
    font-size: 23px;
    margin-bottom: 20px;
  }

  .course__heading h3 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .course__select select {
    text-transform: none;
    outline: 0;
    border: 1px solid #95a1bb54;
    color: #95a1bb;
    width: 496px;
    height: 46px;
    padding: 11px;
  }

  .course_selector {
    display: flex;
    gap: 60px;
    margin-top: 25px;
  }

  .course__select input {
    width: 496px;
    height: 46px;
    border: 1px solid #d9deea;
    color: #d9deea;
    padding: 10px;
  }

  .course__select h4 {
    margin-bottom: 16px;
  }

  .chip__btn {
    display: flex;
    gap: 13px;
  }

  .spec__header h4 {
    color: white;
    background-color: #0c1c36;
    width: 220px;
    height: 43px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
  }

  .course__specialization {
    margin-top: 33px;
  }

  .spec__detail {
    background: #00054f0d;
    padding: 2px 28px;
    border-radius: 4px;
    margin-top: 50px;
    height: 372px;
  }

  div.ck-editor__editable {
    min-height: 252px;
  }

  .para__heading {
    margin: 36px 0px;
  }

  h4.course__overview {
    margin-top: 75px;
  }

  .gender__neutral {
    margin-top: 88px;
  }

  .gender__form td input {
    border: 1px solid #d9deea;
    margin-top: 12px;
    height: 46px;
    width: 280px;
    padding: 10px;
    color: lightgrey;
  }

  .gender__form td {
    text-align: left;
  }

  .gender__form {
    width: 1400px;
    background: #00054f05;
    border-radius: 4px;
    margin-top: 30px;
    padding: 25px;
  }

  .gender__form td h3 {
    font-size: 16px;
  }

  .obc {
    display: flex;
    gap: 45px;
  }

  .obc__input input {
    width: 144px !important;
  }

  .spec__header h3 {
    margin-top: 30px;
  }

  .scholarship__intro p {
    line-height: 35px;
  }

  .scholarship__detail .faculty-list p {
    margin: 14px 35px;
    padding: 20px;
  }
  .faculty-list.sch__detail table {
    width: 88%;
    height: 162px;
  }
  .add___table {
    margin-top: 35px;
  }
  .add___table {
    margin-top: 35px;
    display: FLEX;
    gap: 40px;
    align-items: CENTER;
  }
  .add___table button {
    background: none;
    width: 116px;
    height: 38px;
    border-radius: 48px;
    font-size: 14px;
    font-family: "WorkSans-SemiBold";
  }
}
.nav-tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  margin-bottom: 10px;

  .tabbing-button {
    button {
      border: none;
      background: none;
      color: #ffffff9d;
      font-size: 16px;
      @media (max-width: 1640px) {
        font-size: 14px !important;
      }
    }
  }
}
.tabbing-button.active button {
  color: #fff;
  border-bottom: 2px solid #e19c28;
  padding-bottom: 10px;
  font-weight: bold;
}

.campus-life.faq_wrapper {
  .accordion-item {
    background-color: #ffffff1f;
    border-radius: 12px;
    margin-bottom: 20px;
    .accordion-button {
      background-color: #ffffff1f;
      border-radius: 12px;
      word-break: break-all;
    }
  }
}
.cutoff {
  text-align: left;
  .css-1q9fcw6-control {
    min-height: 30px !important;
    height: 33px !important;
    border-radius: 8px;
  }
  .css-qbdosj-Input {
    margin: 0 !important;
  }
  table {
    th:nth-child(5) {
      border-right: 0;
    }
    th,
    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.63);
      border-right: 1px solid rgba(255, 255, 255, 0.63);
      text-align: center;
    }
    th,
    td:last-child {
      border-right: 0;
    }
    .td_left {
      border-right: 1px solid rgba(255, 255, 255, 0.63);
      max-width: 200px;
      word-break: break-all;
    }
  }
  .entrance_exm {
    margin-bottom: 20px;
    select {
      background-color: #1e2742;
      border: none;
      outline: 0;
      color: rgba(255, 255, 255, 0.877);
      width: 100px;
      margin: 0px 12px;
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
}
.tab_info {
  .dropdown-menu {
    background-color: #0c1c36;

    .dropdown-item {
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.582);
    @media (max-width: 1640px) {
      font-size: 13px;
    }
    &::after {
      position: absolute;
      color: rgba(255, 255, 255, 0.582);
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      right: -16px;
      top: 50%;
    }
  }
}
.btn-primary {
  --bs-btn-active-bg: transparent !important;
}
.user-data {
  display: flex;
  align-items: center;
  position: relative;
  .user-img {
    width: 80px;
    position: absolute;
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  .user {
    width: 60px;
  }
}
.user_detail {
  p,
  h5 {
    word-break: break-word;
    max-width: 150px;
    color: white !important;
  }
}
.accordion-button::after {
  background-image: url(../../assets/images/arrow-down.png) !important;
  margin: 0;
}
.carrer-cluster {
  display: flex;
  gap: 15px;
  .user_detail {
    line-height: 25px;
    background: #1e2742;
    padding: 0.7rem;
    border-radius: 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
    }
  }
  .course_name {
    color: #ffffff94 !important;
  }
  .cour {
    border-bottom: 1px solid grey;
  }
}
.overview-img {
  width: 200px;
}
.wrap-course-subject {
  .course_name {
    overflow: visible;
    max-width: 100%;
    text-overflow: inherit;
  }
}
