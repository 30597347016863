//  Add OverDetails Page SCSS Start Here 
.wrapper_overviewpage {
    text-align: initial;
    color: #FFFFFF;

    .row {
        flex-wrap: wrap;
    }

    .viewdetails_text h5 {
        font-size: 23px;
        margin-bottom: 20px;

    }
}

.wrap_header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        font-size: 20px;
        color: #fff;

    }

    .editor-btn .btn {
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        font-size: 14px;
        margin-left: 10px;

        &:hover {
            color: #fff;
            background: #000000;
        }
    }
}

.viewdetails_text {
    margin-bottom: 20px;


    p {
        margin-bottom: 0;
        line-height: 24px !important;

    }
}
.add-college{
    .facility-check{
        li{
            margin-right: 60px;
        }
    }
}
.addoverview-data {
   border-radius: 15px;
    position: relative;
    background: #46517081;
    padding: 1rem;
    margin-right: 0.9rem;
    label {
        font-size: 16px;
        margin-bottom: 10px;
        width: 100%;
    }

    
        
    }

    .overview_toggle {
        position: relative;

        &::after {
            position: absolute;
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            right: 20px;
            top: 12px;
            transform: translate(50%, 50%);
            color: #95A1BB;

        }
    }

    textarea {
        resize: none;
    }

    .input-group {
        background-color: #1E2742;
        padding-right: 10px;
        margin-bottom: 20px;
        width: 70%;
        margin-right: 10px;
        border-radius: 4px;

        .img-upload {}

        img {
            width: 25px;
        }
    }

    .form-control {
        background-color: #1E2742;
        border: 0;
        color: #fff;
        font-size: 13px;
        &:focus {
            box-shadow: none;
            background-color: #1E2742;
           
        }
    }

.overview_studentdetails {
    .input-group{
        display: flex;
        align-items: center;
        gap: 10px;
        .form-control{
            width: 200px;
            padding: 6px 7px;
        }
    }
    

    h6 {
       
    }

    .overview_studentdetails_bx {
        padding-left: 15px;

        position: relative;

        .college-detail-subheading{
            display: flex;
    justify-content: space-between;
    max-width: 925px;
        }

        .add-button {
            width: auto;
            position: absolute;
            right: 0px;
            top: 50%;
            padding: 5px 10px;
            transform: translate(50%, -50%);
            background: #050e29;
            padding: 5px 15px;
            border-radius: 20px;
            border: 1px solid #fff;
            color: #fff;
        }


        .student-textarea {
            background: #46517081;
           padding: 20px;
            color: #000;
            position: relative;
            border-radius: 4px;
        }

        label {
            font-size: 16px;
            color: #E19C28;
display: block;
            margin: 10px 0px;
        }


    }


    .student_checkbx {
        display: flex;

        .selecthostel {
            margin-left: 85px;
        }

        label {
            margin-right: 10px;
        }

    }

    .addplacement-data {
        top: 120px;
    }

    .add-image-bx {
        display: flex;
        align-items: center;
        .subheader-right-btm{margin: 0;}
        button {
            padding: 6px 15px;
            border-radius: 4px;
        }
    }

    .images-gallery {
        display: flex;
       
        gap: 20px;
        .col-md-2{position: relative;}
        .input-group{
            margin: 0;
            width: 317px;
        }
        
         label{margin-bottom: 0!important;}
    }

   

}

.overview-add{
    padding-left: 0!important;
}
.header{
    display: flex!important;
    justify-content: space-between;
    align-items: center;
}

.placement-companyinfo{
    img{
        width: 130px;
    height: 130px;
    border-radius: 12px;
    
    }
    p{
       
        text-transform: capitalize;
    }
}

.student-textarea {
    background: #46517081;border-radius: 10px;
}
p.college-chip {
    background: #1a1a1a21;
    color: white!important;
    text-align: center;
    border-radius: 10px;
    max-width: 255px;
    padding: 6px;
    font-size: 12px!important;
    font-weight: bold;
    
}
