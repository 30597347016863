.right-panel .right-panel-wraper {
    padding: 11px 35px;
}
.subadmin-breadcrums {
        font-size: 14px;
        font-weight: 600;
        padding: 8px 10px;
        border-bottom: 1px solid #d3d3d373;
        color: black;
        svg{
            margin: 0px 6px;
        }
    
}
.subadminbread{
    h1{
    color: #1E6A9C!important;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 20px 0px;
    }
}
button.edit-history {
    background: #F5D4FF;
    border: none;
    padding: 5px 15px;
    border-radius: 50px;
}
.add-page-heading{
    background: #D4EEFF;
    border: none;
    padding: 5px 15px;
    border-radius: 50px;
    max-width: fit-content;
    text-align: center;
    font-size: 16px!important;
    padding: 8px 15px;
   
    @media(max-width: 1740px){
        font-size: 14px!important;
    }
    @media(max-width: 1640px){
        font-size: 12px!important;
        
    }
    @media(max-width: 1440px){
        font-size: 11px!important;
    }
}
.college-header{
    display: flex;
    gap: 10px;
    img{
        width: 40px;
    background-color: #cf341513;
    padding: 0px 9px;
    border-radius: 50px;
    height: 42px;
    @media(max-width: 1740px){
        width: 35px;
    padding: 0px 9px;
    height: 35px;
    }
    @media(max-width: 1740px){
        width: 30px;
    height: 30px;
    }
    }
    p{
        margin: 0;
    }
    h1{
        margin: 0;
        
    }
}
