.personal-info{
    display: flex;
    align-items: center;
    gap: 15px;
    img{
        width: 80px;
    }
}

.spec-info{
    background: #46517081;
    border-radius: 10px;
}