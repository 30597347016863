.bx-login {
  background-image: url("../../../public/assets/images/background.svg");
  width: 100%;
  height: 100vh;
  background-color: #000D2A;
  position: fixed;
  background-blend-mode: luminosity;
  background-size: cover;
  .form-control{
    color: #000;
    border: 1px solid #ced4da;
    background-color: #fff!important;
    font-weight: 400;
    padding-right: 49px;
  }
  .otp-email{
    font-weight: 500;
    font-size: 16px;
    color: black!important;
    @media (max-width: 1640px) {
      font-size: 14px;
     
    }
    
  }

  .row {
    height: 100vh;
    align-items: center;
    --bs-gutter-x: 0;
  }

  .login-pg {
    background: white;
    display: flex;
    text-align: left;
    justify-content: center;
    padding: 70px 50px;
    border-radius: 49px;
    height: auto;
    margin: 44px 0px;
    margin-right: 80px;
    width: auto;
    
    


    form {
      margin-top: 15px;
    }

    .login-contant {
      text-align: left;
      label{
        margin-bottom: 10px;
      }

      h5 {
        margin-bottom: 30px;
        font-weight: bold;
        color: #000;
        font-size: 20px;
        @media (max-width: 1366px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      p {
        color: #272727d9;
        margin-bottom: 30px;
        @media (max-width: 1640px) {
          
          margin-bottom: 20px;
        }
        
      }
    }
  }

  .input-bx {
    
    input.inputStyle {
      width: 72px!important;
      height: 75px;
      @media (max-width: 1800px) {
        
      height: 65px;
       }
       @media (max-width: 1640px) {
       width: 60px!important;
       height: 60px;
       }
       @media (max-width: 1440px) {
        width: 55px!important;
        height: 52px;
        }
  }
  .otp{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .otp-number{
    gap: 40px;
    margin-bottom: 40px;
    outline: 0;
    @media (max-width: 1800px) {
     gap: 17px;
    }
    @media (max-width: 1640px) {
      gap: 15px;
     }
  }
  
    .input_wrap {
      margin-bottom: 40px;
      input {
        height: 80px;
        font-size: 20px;
        @media (max-width: 1640px) {
          height: 60px;
          font-size: 15px;          
        }
        @media (max-width: 1366px) {
          height: 48px;
          font-size: 13px;
        }

        &:focus {
          border-color: rgb(65, 62, 62);
          box-shadow: none;

        }
      }
.input-field-error{
  
  font-size: 12px;
}
    }

    .input-field-error {
      color: red;
      text-align: left;
      text-transform: capitalize;
     
    }

    .forgot {
      text-align: left;
      color: #000d2a;
      background: transparent;
      border: none;
      
      color: #000d2a;
     
      @media (max-width: 1366px) {
        font-size: 12px;
        margin-top: -12px;
        a{
          text-decoration: underline;
        }
      }
    }

    .btn-login {
      text-align: center;
      button {
        width: 277px;
        height: 87px;
        background: #000d2a;
        color: white;
        font-size: 23px;
        border-radius: 4px;
        margin-top: 70px;
        @media (max-width: 1640px) {
          height: 60px;
          font-size: 20px;
          width: 250px;          
        }
        @media (max-width: 1366px) {
          width: 150px;
          height: 50px;
          font-size: 18px;
          border-radius: 6px;
          margin-top: 35px;
        }
      }
    }
  }

  .bg-left-banner {
    .logo {
      img {
        width: 360px;

        @media (max-width: 1366px) {
          width: 215px;
        }
      }

      p {
        color: white;
        margin-top: 60px;
        font-size: 18px;

        @media (max-width: 1366px) {
          margin-top: 40px;
          font-size: 15px;
        }
      }
    }
  }
}

.wrapper_overviewpage {
  .wrap_header {
    h6 {
      @media (max-width: 1700px) {
        font-size: 18px;
      }
    }
  }

  .viewdetails_text {
    h5 {
      font-size: 21px;
    }
  }
}

.wrapper_overviewpage {
  .overview_studentdetails {
    @media (max-width: 1550px) {
      font-size: 14px;
    }

  }

  .wrap_header {
    h6 {
      font-size: 16px;
    }
  }

  .viewdetails_text {
    h5 {
      font-size: 20px;
    }
  }

}

.wrapper_overviewpage{
.viewdetails_text p  {
@media (max-width: 1440px) {
  font-size: 14px;
}
}
}
.overview_studentdetails_bx{
  .add-button{
    right: 170px;
}
label{
  font-size: 14px;
.form-control{
  font-size: 14px;
}
}
}
label{
  font-size: 14px;
.form-control{
  font-size: 14px;
}
}

.wrapper_overviewpage {
  p {
    font-size: 14px;
  }
}

.eye_icon{
  position: relative;
  cursor: pointer;
  img{
    // position: absolute;
    // width: 20px;
    // right: 5px;
    // top: 5px;
    // transform: translate(-50%, 50%);
    position: absolute;
    width: 20px;
    right: 85px;
    top: 53px;
    transform: translate(-50%, 50%);
  }
}
.bx-login{
  .updatepswd-pg{
    display: block;
    .input-bx{
      .btn-login{
        button{
          margin-top: 0;
        }
      }
    }
  }
}
